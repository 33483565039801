import { FeaturesService } from "../../api-services";
import { FieldValues } from "react-hook-form";

export const useFeatures = () => {
  const featuresAsync = (page: number, limit: number) =>
    FeaturesService.getFeatures(page, limit);
  const featuresDeleteAsync = (id: number) =>
    FeaturesService.deleteFeatures(id);
  const featuresAddAsync = (data: FieldValues) =>
    FeaturesService.addFeatures(data);
  const setAddAsync = (data: FieldValues) => FeaturesService.addSet(data);
  const setDeleteAsync = (id: number) => FeaturesService.deleteSet(id);
  const handleUpdateChageAsync = (id: any,index:any,value:any) => FeaturesService.handlecheckUncheck(id,index,value);


  return {
    featuresAsync,
    featuresDeleteAsync,
    featuresAddAsync,
    setAddAsync,
    setDeleteAsync,
    handleUpdateChageAsync
  };
};
