import { useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { setId, setNames } from './utils';
import { settingOptions } from '../../../utils';
import { useFeatures } from '../../../hooks';
import { FilteredTableData } from '../../../utils/helper';
import { Button, useModal } from '../../Atoms';
import { AddFeatureModal, AddSetModal } from '../../Molecules';
import { FieldValues } from 'react-hook-form';
import { ProceedModal } from '../../Molecules/Models/ProceedModal';

export const FeaturesHeader = () => {
  const [usersData, setUsersData] = useState<any>([]);
  const { featuresAsync, featuresDeleteAsync, featuresAddAsync, setAddAsync, setDeleteAsync,handleUpdateChageAsync } = useFeatures();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [tableData, setTableData] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(false);

  const [tableSetID, setTableSetID] = useState<string[]>([]);
  const [rowData, setRowData] = useState<number | null>(null);
  const [deleteSetID, setDeleteSetID] = useState<number | null>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, limit: number) => {
    return await featuresAsync(data, limit);
  };
  const handleDelete = async (id: number) => {
    try {
      const data = await featuresDeleteAsync(id);
      if (data) {
        handlePageChange(pageNumber);
        proceedModalClose();
      }
    } catch (error) {}
  };
  function fetchValues(arr:any, key:any) {
    let values = [];
  
    // Iterate through the outermost array
    for (const innerArray of arr) {
      // Iterate through the inner arrays
      for (const innerObject of innerArray) {
        // Check if the inner object has the desired key
        if (key in innerObject) {
          values.push(innerObject[key]);
        }
      }
    }
  
    return values[0];
  }
  
    const handleCheckBox = async(e: any , data : any,i:any,setid:any,pageNo:any) => {
      const id =fetchValues(setid,data)
      const check = e[data]?.props.checked
      const response =await handleUpdateChageAsync(id,e.id,!check)
      handlePageChange(pageNo)
    };

    
  const handlePageChange = async (pageNumber: number) => {
    setLoading(true);
    const data = await getUserData(pageNumber, 10);
    if (data) {
      setLoading(false);
      setTotalDataCount(data.count);
      setPageNumber(pageNumber);
      const featureid =setId(data.data.rows)
      setTableSetID(setId(data.data.rows));
      const keys: Array<Array<string>> = setNames(data.data.rows).map((data: any) => Object.keys(data));
      const longestArray = keys.reduce((acc, curr) => (curr.length > acc.length ? curr : acc));
      longestArray.push('Action', 'id');
      setTableData(longestArray);
      const filteredData = await FilteredTableData(setNames(data.data.rows), longestArray);

      filteredData.forEach((item) => {
        longestArray.map((data, index) => {
          if (data === 'name' || data === 'id' || data === 'rowId') return item[data];
          item[data] = (
            <input
              key={index}
              type='checkbox'
              onChange={() => handleCheckBox(item , data,index,featureid,pageNumber)}
              checked={item[data] === 'name' ? item[data] : item[data] === true ? true : false}
            />
          );
        });
        item.Action = (
          <div className='flex justify-center'>
            <Button onClick={() => modalData(item.id)} className='bg-red-600 !text-white'>
              Delete Feature
            </Button>
          </div>
        );
      });

      setUsersData(filteredData);
    }
  };
  useMemo(async () => {
    handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFeature = async (data: FieldValues) => {
    try {
      const res = await featuresAddAsync?.(data);
      if (res) {
        handlePageChange(pageNumber);
        closeFeature?.();
      }
    } catch (error) {}
  };

  const handleSet = async (data: FieldValues) => {
    try {
      const res = await setAddAsync?.(data);
      if (res) {
        handlePageChange(pageNumber);
        close?.();
      }
    } catch (error) {}
  };
  const { Modal: AddModal, open, close } = useModal(<AddSetModal submit={handleSet} />, 'Add Set');
  const {
    Modal: AddFeatModal,
    open: openFeature,
    close: closeFeature,
  } = useModal(<AddFeatureModal submit={handleFeature} />, 'Add Feature');

  const handleDeleteRow = async (id: number) => {
    modalDeleteData(id);
  };

  const handleProceed = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalClose();
    }
    if (rowData !== null) {
      handleDelete(rowData);
    }
  };

  const modalData = (id: number) => {
    proceedModalOpen();
    setRowData(id);
  };

  const {
    Modal: AddProceedModal,
    open: proceedModalOpen,
    close: proceedModalClose,
  } = useModal(<ProceedModal proceed={handleProceed} />, '');

  const handleDeleteProceed = async (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return deleteModalClose();
    }

    if (deleteSetID) {
      try {
        const data = await setDeleteAsync?.(deleteSetID);
        if (data) {
          handlePageChange(pageNumber);
          deleteModalClose();
        }
      } catch (error) {}
    }
  };

  const modalDeleteData = (id: number) => {
    deleteModalOpen();
    setDeleteSetID(id);
  };

  const {
    Modal: DeleteModal,
    open: deleteModalOpen,
    close: deleteModalClose,
  } = useModal(<ProceedModal proceed={handleDeleteProceed} />, '');

  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900 p-6'>
      <Navbar options={settingOptions} />
      <div className='bg-white p-4 shadow-md '>
        <div className='justify-between flex'>
          {/* <div>
            <p className="text-[16px] mb-3">feature</p>
          </div> */}
          <div className='flex justify-between gap-4 mb-2 ' style={{ marginLeft: 'auto' }}>
            <Button onClick={() => open()} color='primary' size='small' className='w-full !px-10 !bg-blue-600 mb-2'>
              Add set
            </Button>
            <Button
              onClick={() => openFeature()}
              color='primary'
              size='small'
              className='w-full !px-10 !bg-blue-600 mb-2'
            >
              Add feature
            </Button>
          </div>
        </div>
        <Table
          tableDate={usersData}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          keysToFilter={tableData}
          totalDataCount={totalDataCount}
          tableSetID={tableSetID}
          onDeleteRow={handleDeleteRow}
          isLoading={isLoading}
        />
        <AddModal />
        <AddFeatModal />
        <AddProceedModal />
        <DeleteModal />
      </div>
    </main>
  );
};
