import { useEffect, useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { tableData, tableHeaderRow } from './utils';
import { settingOptions } from '../../../utils';
import { useAllWorlds } from '../../../hooks';
import { FilteredTableData } from '../../../utils/helper';
import { TableHeader } from '../../Molecules';
import { Button, Spinner } from '../../Atoms';
import { ProceedModal } from '../../Molecules/Models/ProceedModal';
import { useModal } from '../../Atoms';
import { getSpinnerSize, getSpinnerStokeColor } from '../../Atoms/Button/utils';

export const AllWorldsHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState(10);
  const [usersData, setUsersData] = useState<any>([]);
  const { allWorldsAsync, updateWorldsAsync, updateStatusWorldsAsync,addToFeaturedWorldsAsync } = useAllWorlds();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<{
    id: number | null;
    value: String;
    orderNo: number | null;
  }>({
    value: '',

    id: null,
    orderNo: null,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, pageSize: number) => {
    try {
      return await allWorldsAsync(data, pageSize);
    } catch (error) {}
  };

  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };
  const handelUpdateIndex = async (newIndex: any, oldIndex: number, id: number) => {
    try {
      if (newIndex <= 0 || Number(newIndex) === oldIndex) {
        alert('Index should be greater than 0 and different from the old index.');
        return;
      }

      const smallIndex = Math.min(oldIndex, Number(newIndex));
      const greaterIndex = Math.max(oldIndex, Number(newIndex));
      const data = await getUserData(pageNumber, entries);
      proceedModalClose();
      const updatedData = data?.data.rows.filter(
        (item: any) => item.orderNo >= smallIndex && item.orderNo <= greaterIndex
      );

      if (oldIndex > Number(newIndex)) {
        updatedData.reverse();
      }
      await updateWorldsAsync?.({
        // body: updatedData,
        worldId: id,
        newOrderNo: newIndex,
      });
    } catch (error) {
      console.error('Error occurred:', error);
      // Handle error
    } finally {
      setUsersData([]);
      handlePageChange(pageNumber);
    }
  };

  const updateStatus = async (id: number) => {
    try {
      await updateStatusWorldsAsync?.(id);
      handlePageChange(pageNumber);
    } catch (error) {}
  };

 const handleAddToFeatureed = async (id: number) => {
    try {
      await  addToFeaturedWorldsAsync?.(id,id);
      handlePageChange(pageNumber);
    } catch (error) {}
  }

  const handlePageChange = async (pageNumber: number) => {
    setLoading(true);
    const data = await getUserData(pageNumber, entries);
    if (data) {
      setLoading(false);
      setTotalDataCount(data.data.count);
      setPageNumber(pageNumber);
      const filteredData = await FilteredTableData(data.data.rows, tableData);
      filteredData.forEach((item) => {
        const isTrue = item.status?.toLowerCase() === 'publish';
        const isFeatured = item.isFeatured;;
        const orderNo = item.orderNo;
        if (item.entityType === 'USER_WORLD') {
          item.status = (
            <Button
              onClick={() => updateStatus(item.id)}
              color='primary'
              size='small'
              className={`'flex justify-center mx-3'${isTrue ? '!bg-[#dc3545]' : '!bg-[#28a745]'}`}
            >
              {isTrue ? 'UnPublish' : 'Publish'}
            </Button>
          );
        }
        item.thumbnail = (
          // eslint-disable-next-line jsx-a11y/alt-text
          <div className='flex justify-center items-center'>
            <img className='rounded-full bg-blue-500 w-16 h-16 m-n1' src={item.thumbnail} alt='Thumbnail' />
          </div>
        );
        item.orderNo = (
          <input
            onBlur={(e) => modalData(e.target.value, orderNo, item.id)}
            type='number'
            defaultValue={item.orderNo}
            className='w-24 h-8 text-sm px-2 py-1 border rounded'
            min={0}
            onKeyDown={(e) => {
              if (e.key === '-' || e.key === '.') {
                e.preventDefault();
              }
            }}
          />
        );
        item.isFeatured = (
          <Button
            onClick={() => handleAddToFeatureed(item.id)}
            // color='primary'
            // size='small'
            className={isFeatured ? '!bg-red-600 !text-white ' : '!bg-[#28a745] !text-white '}
            // style={{backgroundColor : "red !important"}}
          >
            {isFeatured ? 'Remove' : 'Add'}
          </Button>
        );
      });
      setUsersData(filteredData);
    }
  };
  useMemo(async () => {
    setUsersData([]);
    await handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, entries, searchTerm]);

  const handleProceed = async (isConfirmed: boolean) => {
    if (!isConfirmed) {
      setUsersData([]);
      await handlePageChange(pageNumber);
      return proceedModalClose();
    }
    if (rowData?.id !== null && rowData?.value !== null && rowData.orderNo) {
      handelUpdateIndex(rowData.value, rowData.id, rowData.orderNo);
    }
  };

  const modalData = (value: String, id: number, orderNo: number) => {
    proceedModalOpen();
    setRowData((prev) => ({
      ...prev,
      value,
      id,
      orderNo,
    }));
  };

  const {
    Modal: AddProceedModal,
    open: proceedModalOpen,
    close: proceedModalClose,
  } = useModal(<ProceedModal proceed={handleProceed} />, '');

  const spinnerClasses = [getSpinnerStokeColor('neon'), getSpinnerSize('large')].join(' ');
  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900 p-6'>
      <Navbar options={settingOptions} />
      <div className='bg-white p-4 shadow-md '>
        <div className='justify-between flex'>
          {/* <div>
            <p className="text-[16px] mb-3">All Worlds</p>
          </div> */}
        </div>
        <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />

        <Table
          tableDate={usersData}
          searchItem={searchTerm}
          entries={entries}
          keysToFilter={tableData}
          tableHeaderRow={tableHeaderRow}
          totalDataCount={totalDataCount}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          isLoading={isLoading}
        />

        <AddProceedModal />
      </div>
    </main>
  );
};
