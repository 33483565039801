import { useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { obj, tableData, tableHeaderRow } from './utils';
import { changeKeyNameOfObject, settingOptions } from '../../../utils';
import { useItems } from '../../../hooks/';
import { FilteredTableData, convertTimeZone } from '../../../utils';
import { Button, RefetchIcon, Spinner, useModal } from '../../Atoms';
import { AddItemModal, EditItemModal } from '../../Molecules';
import { ProceedModal } from '../../Molecules/Models/ProceedModal';
import { DeleteModal } from '../../Molecules/Models/deleteModal';
import { TableHeader } from '../../Molecules';

export const ItemHeader = () => {
  const [usersData, setUsersData] = useState<any>([]);
  const { itemAsync, itemActiveAsync, itemAddAsync, itemDeleteAsync ,editItemAsync} = useItems();
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [totalDataCount, setTotalDataCount] = useState(0);
  const [entries, setEntries] = useState(10);

  const [isTrue, setIsTrue] = useState<boolean>(false);
  const [rowData, setRowData] = useState<{ id: number; value: boolean }>({
    id: 0,
    value: false,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, entries: number) => {
    try {
      return await itemAsync(pageNumber, entries);
    } catch (error) {}
  };
  const handleActivation = async (id: number, value: boolean) => {
    try {
      const data = await itemActiveAsync(id, !value);

      if (data) {
        await handlePageChange(pageNumber);
      }
    } catch (error) {
      console.log('err', error);
    } finally {
      proceedModalClose();
    }
  };

  const handlePageChange = async (pageNumber: number) => {
    try {
      setIsTrue(false);
      setLoading(true);
      const data = await getUserData(pageNumber, entries);
      if (data) {
        setLoading(false);
        setTotalDataCount(data.count);
        setPageNumber(pageNumber);
        const filteredData = await FilteredTableData(changeKeyNameOfObject(data.data, obj), tableData);
        filteredData.forEach((item) => {
          const value = item.Active;
          item.createdAt = convertTimeZone(item.createdAt);
          item.Active = (
            <div className='flex justify-center mx-3'>
              <Button
                onClick={() => modalData(item.id, value)}
                className={!value ? '!bg-[#28a745] !text-white ' : '!bg-red-600 !text-white'}
              >
                {!item.Active ? 'Active' : 'nonActive'}
              </Button>
            </div>
          );
          item.iconLink = (
            <img
              className='rounded-full w-[50px] h-[50px] mx-auto w-16 h-16 m-n1'
              src={item.iconLink}
            />
          );
          item.delete = (
            <div className='flex justify-center mx-3'>
              <Button onClick={() => deleteData(item.id)} className='bg-blue-600'>
                Delete
              </Button>
            </div>
          );
          item.edit = (
            <div className='flex justify-center mx-3'>
              <Button onClick={() => EditPata(item)} className='bg-blue-600'>
                Edit
              </Button>
            </div>
          );
        });
        setUsersData(filteredData);
        setIsTrue(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useMemo(async () => {
    setUsersData([]);
   await handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber,entries, searchTerm]);
  const handleDataItem = async (data: FormData ) => {
    try {
      await itemAddAsync?.(data);
      handlePageChange(pageNumber);
      close();
    } catch (error) {}
  };
  const EditDataItem = async (data: any) => {
    try {
      await editItemAsync?.(data,data?.itemId);
      handlePageChange(pageNumber);
      EditModalClose();
    } catch (error) {}
  };
  const { Modal: AddModal, open, close } = useModal(<AddItemModal save={handleDataItem} />, 'Add Item');

  const handleProceed = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalClose();
    }
    handleActivation(rowData.id, rowData.value);
  };

  const handlDelete = async (id: number) => {
    try {
      const data = await itemDeleteAsync(id);
      if (data) {
        await handlePageChange(pageNumber);
        proceedModalCloseDelete();
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  const handleProceedDelete = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalCloseDelete();
    }
    handlDelete(rowData.id);
  };
  const modalData = (id: number, value: boolean) => {
    proceedModalOpen();
    setRowData((prev) => ({
      ...prev,
      id,
      value,
    }));
  };
  const deleteData = (id: number) => {
    proceedModalOpenDelete();
    setRowData((prev) => ({
      ...prev,
      id,
    }));
  };
  const EditPata = (item:any) => {
    EditModalOpen();
    setRowData((prev) => ({
      ...prev,
      item,
    }));
  };
  const {
    Modal: AddProceedModal,
    open: proceedModalOpen,
    close: proceedModalClose,
  } = useModal(<ProceedModal proceed={handleProceed} />, '');

  const {
    Modal: EditModal,
    open: EditModalOpen,
    close: EditModalClose,
  } = useModal(<EditItemModal save={EditDataItem} rowData={rowData} />, 'Edit Model');

  const {
    Modal: AddProceedDeleteModal,
    open: proceedModalOpenDelete,
    close: proceedModalCloseDelete,
  } = useModal(<DeleteModal proceed={handleProceedDelete} />, '');
  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };
  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900 p-6'>
      <Navbar options={settingOptions} />
      <div className='bg-white p-4 shadow-md'>
        <div className='justify-between flex'>
          {/* <div>
            <p className="text-[16px] mb-3">Items</p>
          </div> */}
          <div className='flex justify-between gap-2' style={{ marginLeft: 'auto' }}>
            <button onClick={() => handlePageChange(pageNumber)}>{!isTrue ? <Spinner /> : <RefetchIcon />}</button>
            <Button onClick={open} color='primary' size='small' className='w-full !px-10 !bg-[#3B7DDD] mb-2 '>
              Add item
            </Button>
          </div>
        </div>
        <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />

        <Table
          tableDate={usersData}
          searchItem={searchTerm}
          entries={entries}
          keysToFilter={tableData}
          tableHeaderRow={tableHeaderRow}
          totalDataCount={totalDataCount}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          isLoading={isLoading}
        />
        <AddModal />

        <AddProceedModal />
        <AddProceedDeleteModal />
        <EditModal></EditModal>
      </div>
    </main>
  );
};
