import { EMAIL_REGEX } from "../../../utils"
import { FormInput as Input, } from "../../Molecules"

export const inputs = [
    {
      name: 'email',
      label: 'Email address',
      placeholder: 'Enter your email',
      type:"text",
      required: true,
      InputField: Input,
      rules: {
        required: { value: true, message: 'email can not be empty' },
        pattern: {
          value: EMAIL_REGEX,
          message: 'please enter correct email address',
        },
      },
    },
    {
      name: 'password',
      label: 'Password',
      placeholder: 'Enter your password',
      required: true,
      type:"password",
      InputField: Input,
      rules: {
        required: { value: true, message: 'password can not be empty' },
      },
    },
    
  ]