// import CoreAPIService from "./CoreAPIService";
import { API_ENDPOINTS } from "../utils";
import { FieldValues } from "react-hook-form";
import axios from "axios";
const BASE_API_ENDPOINT = process.env.REACT_APP_PUBLIC_NETWORK_TYPE === "testnet" ?  process.env.REACT_APP_PUBLIC_API_TESTNET_BACKEND_URL : process.env.REACT_APP_PUBLIC_API_MAINNET_BACKEND_URL;
class AuthService {
  getNonce = async (payload: FormData | FieldValues) => {
    try {
      return axios
        .post(BASE_API_ENDPOINT+API_ENDPOINTS.AUTH.LOGIN, 
          payload,
        )
        .then((response) => {
          return response.data;
        });
    } catch (error) {}
  };
  // CoreAPIService.post<NonceResponse>(API_ENDPOINTS.AUTH.LOGIN, payload)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
