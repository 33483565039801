import CoreAPIService from "./CoreAPIService";
import { API_ENDPOINTS, checkAccessToken } from "../utils";
import axios from "axios";
const BASE_API_ENDPOINT = process.env.REACT_APP_PUBLIC_NETWORK_TYPE === "testnet" ?  process.env.REACT_APP_PUBLIC_API_TESTNET_BACKEND_URL : process.env.REACT_APP_PUBLIC_API_MAINNET_BACKEND_URL;
class AnalyticsService {
  getAnalytics = async (pageNumber: any, pageSize: any, data?: any) => {
    let payload;
    const accessToken = checkAccessToken();
    let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.ANALYTICS}/${pageNumber}/${pageSize}/`;
    if (data) {
      url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.ANALYTICS}/${pageNumber}/${pageSize}/`;
    }
    if (data) {
       payload = {
        world_type: data.type,
        world_name: data.world,
        startDate: data.start,
        endDate: data.end,
      };
    } else {
       payload = {};
    }
    try {
      return axios
        .post(url, payload, { headers: { authorization: accessToken } })
        .then((response) => {
          return response.data;
        }).catch((error) => {
          return error.response.data
        });
    } catch (error) {
      return error;
    }
  };
  getAnalyticsUser = async (pageNumber: any, pageSize: any, data?: any) => {
    const accessToken = checkAccessToken();
    let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.ANALYTICSUSER}/${pageNumber}/${pageSize}/`;
    // if (data) {
    //   url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.ANALYTICSUSER}?startDate	=${data.start}&endDate=${data.end}&world=${data.world}&type=${data.type}`;
    // }
    const payload = {};
    try {
      return axios
        .post(url, payload, { headers: { authorization: accessToken } })
        .then((response) => {
          return response.data.data;
        });
    } catch (error) {}
  };
  getAnalyticsUserFIlter = async (
    pageNumber: any,
    pageSize: any,
    data: any
  ) => {
    const accessToken = checkAccessToken();
    let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.ANALYTICSFILTER}/${pageNumber}/${pageSize}/`;
    // if (data) {
    //   url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.ANALYTICSUSER}?startDate	=${data.start}&endDate=${data.end}&world=${data.world}&type=${data.type}`;
    // }
    const payload = {
      username: data.world,
      startDate: data.start,
      endDate: data.end,
    };
    try {
      return axios
        .post(url, payload, { headers: { authorization: accessToken } })
        .then((response) => {
          return response.data;
        });
    } catch (error) {}
  };
}
// getAnalytics = async (page:  number) => CoreAPIService.get<allWorldsResponse>(`${API_ENDPOINTS.PRIVATE.ALLWORLDS}/${page}`)}
// eslint-disable-next-line import/no-anonymous-default-export
export default new AnalyticsService();
