import CoreAPIService from './CoreAPIService'
import { API_ENDPOINTS, checkAccessToken } from '../utils'
import axios from 'axios';
const BASE_API_ENDPOINT =
  process.env.REACT_APP_PUBLIC_NETWORK_TYPE === "testnet"
    ? process.env.REACT_APP_PUBLIC_API_TESTNET_BACKEND_URL
    : process.env.REACT_APP_PUBLIC_API_MAINNET_BACKEND_URL;
class ItemService {
    getItems = async (pageNumber:  number,pageSize:number) => CoreAPIService.get<ItemsResponse>(`${API_ENDPOINTS.PRIVATE.ITEMS}/${pageNumber}/${pageSize}`)
    items = async (id:number, payload:boolean) => CoreAPIService.post<ItemsResponse>(`${API_ENDPOINTS.PRIVATE.ITEMS_TOGGLE_DELETE_ITEM}/${id}`,{toggle:payload})
    addItems = async (payload:FormData) => CoreAPIService.post<ItemsResponse>(`${API_ENDPOINTS.PRIVATE.ITEMS}`,payload)
    deleteItems = async (itemId:number) => CoreAPIService.delete<ItemsResponse>(`${API_ENDPOINTS.PRIVATE.ITEMS}/${itemId}`)

    EditItem = async (data: any, id: any) => {
        const accessToken = checkAccessToken();
        let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.EDITIEM}/${id}`;
        try {
          return axios
            .post(url, data, { 
              headers: { 
                'authorization': accessToken,
                'Content-Type': 'application/json', // Set the content type to JSON
              },
            })
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              return error.response.data;
            });
        } catch (error) {
          return error;
        }
      };
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ItemService()
