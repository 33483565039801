import {EnvironmentsService} from "../../api-services"

export const useEnvironments = () => {
    const environmentsAsync = (page: number,pageSize:number) => {
      return EnvironmentsService.getEnvironments(page,pageSize)
    }
    const environmentActivesAsync = (id: number, value:boolean) => EnvironmentsService.environments(id,value)
    const environmentAddAsync = (data:FormData) => EnvironmentsService.addEnvironments(data)
    
    return {
        environmentsAsync,
        environmentActivesAsync,
        environmentAddAsync
      } 
}