import {AllWorldsService} from "../../api-services"

export const useAllWorlds = () => {
    const allWorldsAsync = (pageNumber: number,pageSize: number) => AllWorldsService.getAllWorlds(pageNumber,pageSize)
    const updateWorldsAsync = (data: any) => AllWorldsService.updateAllWorlds(data)
    const updateStatusWorldsAsync = (data: any) => AllWorldsService.updateStatusWorlds(data)

    const addToFeaturedWorldsAsync = (data: any,payload:any) => AllWorldsService.FeaturedWorlds(data,payload)
    
    
    return {
        allWorldsAsync,
        updateWorldsAsync,
        updateStatusWorldsAsync,
        addToFeaturedWorldsAsync
      } 
}