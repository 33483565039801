import { createSlice } from '@reduxjs/toolkit'

export interface CounterState {
  value: boolean
}

const initialState: CounterState = {
  value: false,
}

export const sideBarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    isShow: (state, data) => {
      const {payload} = data
      state.value =payload
    },
    
  },
})

// Action creators are generated for each case reducer function
export const { isShow } = sideBarSlice.actions

export default sideBarSlice.reducer