import { useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { obj, tableData, tableHeaderRow } from './utils';
import { settingOptions } from '../../../utils';
import { useMuseums } from '../../../hooks';
import { FilteredTableData, changeKeyNameOfObject, convertTimeZone } from '../../../utils/helper';
import { Button, RefetchIcon, useModal } from '../../Atoms';
import { TableHeader, AddMuseumsModal } from '../../Molecules';
import { ProceedModal } from '../../Molecules/Models/ProceedModal';
import { DeleteModal } from '../../Molecules/Models/deleteModal';

import { Spinner } from 'flowbite-react';
export const MuseumsHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setLoading] = useState(false);

  const [entries, setEntries] = useState(10);
  const [usersData, setUsersData] = useState<any>([]);
  const { museumsAsync, museumsActiveAsync, museumsAddAsync, museumsDeleteAsync } = useMuseums();
  const [isTrue, setIsTrue] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowData, setRowData] = useState<{ id: number; value: boolean }>({
    id: 0,
    value: false,
  });
  const [totalDataCount, setTotalDataCount] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, entries: number) => {
    try {
      return await museumsAsync(data, entries);
    } catch (error) {}
  };

  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };
  const handleActive = async (id: number, value: boolean) => {
    try {
      const data = await museumsActiveAsync(id, !value);
      if (data) {
        await handlePageChange(pageNumber);
        proceedModalClose();
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  const handlePageChange = async (pageNumber: number) => {
    setLoading(true);
    setIsTrue(false);
    const data = await getUserData(pageNumber, entries);
    if (data) {
      setLoading(false);

      setTotalDataCount(data.count);
      setPageNumber(pageNumber);
      const filteredData = await FilteredTableData(changeKeyNameOfObject(data.data, obj), tableData);
      filteredData.forEach((item) => {
        const action = item.Action;
        item.createdAt = convertTimeZone(item.createdAt);
        item.Action = (
          <div className='flex justify-center mx-3'>
            <Button
              onClick={() => modalData(item.id, action)}
              className={`!px-3 !py-1 text-sm text-white  ${item.Action ? 'bg-green-600 ' : '!bg-red-600'}`}
            >
              {item.Action ? 'Make Active' : 'Make Inactive'}
            </Button>
          </div>
        );
        item.delete = (
          <Button onClick={() => deleteData(item.id)} className='bg-blue-600'>
            Delete
          </Button>
        );
      });
      setUsersData(filteredData);
      setIsTrue(true);
    }
  };
  useMemo(async () => {
    handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, entries, searchTerm]);
  const handleDataMuseums = async (data: FormData) => {
    try {
      await museumsAddAsync?.(data);
      handlePageChange(pageNumber);
      close();
    } catch (error) {}
  };
  const { Modal: AddModal, open, close } = useModal(<AddMuseumsModal save={handleDataMuseums} />, 'Add Museums');

  const handleProceed = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalClose();
    }
    handleActive(rowData.id, rowData.value);
  };
  const handlDelete = async (id: number) => {
    try {
      const data = await museumsDeleteAsync(id);
      if (data) {
        await handlePageChange(pageNumber);
        proceedModalCloseDelete();
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  const deleteData = (id: number) => {
    proceedModalOpenDelete();
    setRowData((prev) => ({
      ...prev,
      id,
    }));
  };
  const modalData = (id: number, value: boolean) => {
    proceedModalOpen();
    setRowData((prev) => ({
      ...prev,
      id,
      value,
    }));
  };
  const handleProceedDelete = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalCloseDelete();
    }
    handlDelete(rowData.id);
  };

  const {
    Modal: AddProceedModal,
    open: proceedModalOpen,
    close: proceedModalClose,
  } = useModal(<ProceedModal proceed={handleProceed} />, '');

  const {
    Modal: AddProceedDeleteModal,
    open: proceedModalOpenDelete,
    close: proceedModalCloseDelete,
  } = useModal(<DeleteModal proceed={handleProceedDelete} />, '');
  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900 p-6'>
      <Navbar options={settingOptions} />
      <div className='bg-white p-4 shadow-md '>
        <div className='justify-between flex'>
          {/* <div>
            <p className="text-[16px] mb-3">museums</p>
          </div> */}
          <div className='flex justify-between gap-2' style={{ marginLeft: 'auto' }}>
            <button onClick={() => handlePageChange(pageNumber)}>{!isTrue ? <Spinner /> : <RefetchIcon />}</button>
            <Button onClick={open} color='primary' size='small' className='w-full !px-10 !bg-[#3B7DDD] !mb-2'>
              Add Museums
            </Button>
          </div>
        </div>
        <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />
        <Table
          tableDate={usersData}
          searchItem={searchTerm}
          entries={entries}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          keysToFilter={tableData}
          totalDataCount={totalDataCount}
          tableHeaderRow={tableHeaderRow}
          isLoading={isLoading}
        />
        <AddModal />
        <AddProceedModal />
        <AddProceedDeleteModal />
      </div>
    </main>
  );
};
