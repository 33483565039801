import { LobbyWorldService } from "../../api-services";

export const useAllLobbyWorlds = () => {
    const allWorldsAsync = (pageNumber: number,pageSize: number) => LobbyWorldService.getAllWorlds(pageNumber,pageSize)
    const addLoobyWorldAsync = (data: any) => LobbyWorldService.addLobbyAllWorlds(data)
  const updateStatusWorldsAsync = (data: any,index:any) => LobbyWorldService.updateStatusWorlds(data,index)
  

  const deleteStatusWorldsAsync = (data: any) =>
    LobbyWorldService.deleteLobbyWorlds(data);
  const addTextLooby = (data: any) => LobbyWorldService.addText(data);

  return {
    allWorldsAsync,
    addLoobyWorldAsync,
    updateStatusWorldsAsync,
    deleteStatusWorldsAsync,
    addTextLooby,
  };
};
