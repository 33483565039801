import { useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { tableData, tableHeaderRow } from './utils';
import { settingOptions } from '../../../utils';
import { useBackgroundFiles } from '../../../hooks';
import { FilteredTableData, convertTimeZone } from '../../../utils/helper';
import { Button, RefetchIcon, useModal } from '../../Atoms';
import { AddBackgroundFilesModal, TableHeader } from '../../Molecules';
import { DeleteModal } from '../../Molecules/Models/deleteModal';

export const BackgroundFilesHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState(10);
  const [usersData, setUsersData] = useState<any>([]);
  const { backgroundFilesAsync, addBackgroundFilesAsync, deleteBackgrounfFileAsync } = useBackgroundFiles();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const [rowData, setRowData] = useState<{ id: number; value: boolean }>({
    id: 0,
    value: false,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, entries: number) => {
    try {
      return await backgroundFilesAsync(data, entries);
    } catch (error) {}
  };

  const handlePageChange = async (pageNumber: number) => {
    setLoading(true);
    try {
      setUsersData([]);
      const data = await getUserData(pageNumber, entries);
      if (data) {
        setLoading(false);

        setPageNumber(pageNumber);
        setTotalDataCount(data.count);
        const filteredData = await FilteredTableData(data.data, tableData);
        filteredData.forEach((item) => {
          item.createdAt = convertTimeZone(item.createdAt);
          item.delete = (
            <div className='flex justify-center'>
              <Button onClick={() => deleteData(item.id)} className='bg-blue-600'>
                Delete
              </Button>
            </div>
          );
        });
        setUsersData(filteredData);
      }
    } catch (error) {}
  };
  useMemo(async () => {
    handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries]);
  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };
  const handleDataBackgroundFiles = async (data: FormData) => {
    try {
      await addBackgroundFilesAsync?.(data);
      handlePageChange(pageNumber);
      close();
    } catch (error) {}
  };
  const {
    Modal: AddModal,
    open,
    close,
  } = useModal(<AddBackgroundFilesModal save={handleDataBackgroundFiles} />, 'Add Background File');
  const handlDelete = async (id: number) => {
    try {
      const data = await deleteBackgrounfFileAsync(id);
      if (data) {
        await handlePageChange(pageNumber);
        proceedModalCloseDelete();
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  const handleProceedDelete = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalCloseDelete();
    }
    handlDelete(rowData.id);
  };

  const deleteData = (id: number) => {
    proceedModalOpenDelete();
    setRowData((prev) => ({
      ...prev,
      id,
    }));
  };
  const {
    Modal: AddProceedDeleteModal,
    open: proceedModalOpenDelete,
    close: proceedModalCloseDelete,
  } = useModal(<DeleteModal proceed={handleProceedDelete} />, '');
  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900 p-6'>
      <Navbar options={settingOptions} />
      <div className='bg-white p-4 shadow-md '>
        <div className='justify-between flex'>
          {/* <div>
            <p className="text-[16px] mb-3">background files</p>
          </div> */}
          <div className='flex justify-between gap-2' style={{ marginLeft: 'auto' }}>
            <button onClick={() => handlePageChange(pageNumber)}>
              <RefetchIcon />
            </button>
            <Button onClick={open} color='primary' size='small' className='w-full !px-10 !bg-[#3B7DDD] mb-2'>
              Add background files
            </Button>
          </div>
        </div>
        <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />
        <Table
          tableDate={usersData}
          searchItem={searchTerm}
          entries={entries}
          keysToFilter={tableData}
          tableHeaderRow={tableHeaderRow}
          totalDataCount={totalDataCount}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          isLoading={isLoading}
        />
        <AddModal />
        <AddProceedDeleteModal />
      </div>
    </main>
  );
};
