import CoreAPIService from './CoreAPIService'
import { API_ENDPOINTS } from '../utils'

class EnvironmentsService {
    getEnvironments = async (pageNumber:  number,pageSize:number) => CoreAPIService.get<EnvironmentsResponse>(`${API_ENDPOINTS.PRIVATE.ENVIRONMENTS}/${pageNumber}/${pageSize}`)
    environments = async (id:number, value:Boolean) => CoreAPIService.post<EnvironmentsResponse>(`${API_ENDPOINTS.PRIVATE.ENVIRONMENTS}/${id}/${value}`)
    addEnvironments = async (payload:FormData) => CoreAPIService.post<EnvironmentsResponse>(`${API_ENDPOINTS.PRIVATE.ENVIRONMENTS}`,payload)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new EnvironmentsService()
