import {MuseumsService} from "../../api-services"

export const useMuseums = () => {
  const museumsAsync = (pageSize: number,pageNumber:number) => MuseumsService.getMuseums(pageSize,pageNumber)
  const museumsActiveAsync = (id: number, value:boolean) => MuseumsService.getActiveMuseums(id,value)
  const museumsAddAsync = (data:FormData) => MuseumsService.addMuseums(data)
  const museumsDeleteAsync = (museumId:number) => MuseumsService.deleteMuseums(museumId)
    
    return {
        museumsAsync,
        museumsActiveAsync,
        museumsAddAsync,
        museumsDeleteAsync
      } 
}