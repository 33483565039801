import { Button, Select, Typography } from "../../../Atoms";
import { FormInput as Input } from "../..";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { dataForm, version } from "./utils";
import { AddEnvironmentModalProps } from "./interface";
export const AddReactionsModal = ({ save }: AddEnvironmentModalProps) => {
  const [selectData, setSelectData] = useState({
    version: version[0],
  });
  const {
    handleSubmit,
    control,
    register,
  } = useForm();
  const onSubmit = (data: any) => {
    const version = selectData?.version.value;
    const obj = { version}
    data = {
      ...data,
      thumbnail: data["thumbnail"][0],
      android_bundle: data["android_bundle"][0],
      ios_bundle:data["ios_bundle"][0],
      icon3d:data["icon3d"][0],
    };
    const objData = { ...data, ...obj};
    save?.(dataForm(objData))
    
  };
  const labelTextClassName = [
    "mb-1 font-semibold text-neutral-100 dark:text-neutral-600",
  ].join(" ");

  return (
    <form
      className="md:!w-[650px] max-h-[500px] overflow-auto overflow-y-auto overflow-x-hidden"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-6 px-4 ">
        <Input
          required
          className="w-full dark:text-neutral-400"
          control={control}
          label="Reaction Name"
          placeholder="name"
          name="name"
          type="text"
          maxLength={20}
        />
      </div>
      <div className="mb-6 px-4 ">
        <Input
          required
          className="w-full dark:text-neutral-400"
          control={control}
          label="Reactions Category"
          placeholder="Reactions Category"
          name="group"
          type="text"
          maxLength={200}

        />
      </div>
      <div className="-mx-1  flex flex-wrap ">
        <div className="mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2">
          <Typography
            variant="condensed"
            size="caption"
            className={labelTextClassName}
          >
            {"Android Bundle"}
            {<span className="font-bold text-[#FF0000]">*</span>}
          </Typography>
          <input
            required
            className="w-full dark:text-neutral-400"
            type="file"
            accept="image/*"
            {...register("android_bundle")}
          />
        </div>
        <div className="w-full mb-4 px-4 sm:w-1/2 md:w-1/2">
          <Typography
            variant="condensed"
            size="caption"
            className={labelTextClassName}
          >
            {"Ios Bundle"}
            {<span className="font-bold text-[#FF0000]">*</span>}
          </Typography>
          <input
            required
            className="w-full dark:text-neutral-400"
            type="file"
            accept="image/*"
            {...register("ios_bundle")}
          />
        </div>
        <div className="mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2">
          <Typography
            variant="condensed"
            size="caption"
            className={labelTextClassName}
          >
            {"Thumbnail"}
            {<span className="font-bold text-[#FF0000]">*</span>}
          </Typography>
          <input
            required
            className="w-full dark:text-neutral-400"
            type="file"
            accept="image/*"
            {...register("thumbnail")}
          />
        </div>
        <div className="w-full mb-4 px-4 sm:w-1/2 md:w-1/2">
          <Typography
            variant="condensed"
            size="caption"
            className={labelTextClassName}
          >
            {"3D Icon"}
            {<span className="font-bold text-[#FF0000]">*</span>}
          </Typography>
          <input
            required
            className="w-full"
            type="file"
            accept="image/*"
            {...register("icon3d")}
          />
        </div>
        <div className="w-full px-4 sm:w-1/2 md:w-1/2">
          <Select
            label="Select Version"
            options={version}
            value={selectData.version}
            setSelectedCollectionAsset={(data) =>
              setSelectData({ ...selectData, version: data })
            }
          />
        </div>
      </div>
      
      <div className="mb-6 w-full flex justify-end px-4 py-4">
        <Button
          color="primary"
          size="small"
          variant="solid"
          type="submit"
          className="w-[200px] !px-10 !bg-[#3b7ddd]"
        >
          Save change
        </Button>
      </div>
    </form>
  );
};
