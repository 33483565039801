export type Item = {
  value: string;
  label: string;
};

const emptyArrays: Item[] = new Array(11).fill([]);

export const version: any[] = emptyArrays.map((_, index) => {
  return {
    value: index + 1,
    label: index + 1,
  };
});
export const category = [
  {
    value: String(1),
    label: "Cloth",
  },
  {
    value: String(2),
    label: "Avatar",
  },
];
const subcategoryLength = 9;
const subcategoryArray = [
  "Head",
  "Face",
  "Inner",
  "Outer",
  "Accesary",
  "Bottom",
  "Socks",
  "Shoes",
  'Hair'
];
export const subcategory = new Array(subcategoryLength)
  .fill([])
  .map((_, index) => ({
    value: String(index + 1),
    label: subcategoryArray[index],
  }));

export const gender = [
  { value: "0", label: "male" },
  { value: "1", label: "female" },
];

export const dataForm = (data: any) => {
  const formData = new FormData();
   Object.entries(data).forEach(([key, value]) => {
     formData.append(key, data[key]);
  });
  return formData
};
export const validTypes = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
]