/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useMemo, useState } from "react";
import { Navbar, Table } from "../index";
import { tableData, tableHeaderRow } from "./utils";
import { settingOptions } from "../../../utils";
import { useAllLobbyWorlds } from "../../../hooks";
import { FilteredTableData } from "../../../utils/helper";
import { TableHeader, AddTextModaL } from "../../Molecules";
import { Button, Spinner } from "../../Atoms";
import { ProceedModal } from "../../Molecules/Models/ProceedModal";
import { useModal } from "../../Atoms";
import { toast } from "react-toastify";
import { FieldValues } from "react-hook-form";

interface Item {
  lobbyAssetData?: unknown;
  world_id?: string;
  name?: string;
  entityType?: string;
  creator?: string;
  description?: string;
  thumbnail?: string;
  id: number;
  index?: any;
  // Add other fields as necessary
}

export const LobbyWorldsHeader = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [lobbyIndexPayload, setLobbyIndexPayload] = useState<any[]>([]);
  const [entries, setEntries] = useState(10);
  const [usersData, setUsersData] = useState<any>([]);
  const {
    allWorldsAsync,
    addLoobyWorldAsync,
    updateStatusWorldsAsync,
    deleteStatusWorldsAsync,
    addTextLooby,
  } = useAllLobbyWorlds();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [orderNumbers, setOrderNumbers] = useState<{ [key: string]: number }>(
    {}
  );

  const [isValid, setIsValid] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<{
    id: number | null | any;
    value: String;
    orderNo: number | null;
  }>({
    value: "",

    id: null,
    orderNo: null,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, pageSize: number) => {
    try {
      return await allWorldsAsync(data, pageSize);
    } catch (error) {}
  };

  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };
  const handelUpdateIndex = async (
    newIndex: any,
    oldIndex: number,
    id: number
  ) => {
    try {
      if (newIndex <= 0 || Number(newIndex) === oldIndex) {
        alert(
          "Index should be greater than 0 and different from the old index."
        );
        return;
      }

      const smallIndex = Math.min(oldIndex, Number(newIndex));
      const greaterIndex = Math.max(oldIndex, Number(newIndex));
      const data = await getUserData(pageNumber, entries);
      proceedModalClose();
      const updatedData = data?.data.rows.filter(
        (item: any) =>
          item.orderNo >= smallIndex && item.orderNo <= greaterIndex
      );

      if (oldIndex > Number(newIndex)) {
        updatedData.reverse();
      }
      await addLoobyWorldAsync?.({
        // body: updatedData,
        worldId: id,
        newOrderNo: newIndex,
      });
    } catch (error) {
      // Handle error
    } finally {
      setUsersData([]);
      handlePageChange(pageNumber);
    }
  };

  // const updateStatus = async (id: number) => {
  //   try {
  //     await updateStatusWorldsAsync?.(id);
  //     handlePageChange(pageNumber);
  //   } catch (error) {}
  // };

  const handlePageChange = async (pageNumber: number) => {
    setLoading(true);
    const data = await getUserData(pageNumber, entries);
    if (data) {
      setLoading(false);
      setTotalDataCount(data.data.count);
      setPageNumber(pageNumber);
      const filteredData = await FilteredTableData(data.data.rows, tableData);
      filteredData.forEach((item) => {
        // if (
        //   item?.lobbyAssetData?.index != null &&
        //   item?.lobbyAssetData?.index !== undefined
        // ) {
        //   item.lobbyAssetData = (
        //     <input
        //       onBlur={(e) => modalData(e.target.value, item.id)}
        //       type="number"
        //       defaultValue={item.lobbyAssetData.index}
        //       className="w-24 h-8 text-sm px-2 py-1 border rounded"
        //       min={0}
        //       onKeyDown={(e) => {
        //         if (e.key === "-" || e.key === ".") {
        //           e.preventDefault();
        //         }
        //       }}
        //     />
        //   );
        // }
        // if (item.type === "xan") {
        // item.checkbox = (
        //   <Button
        //     onClick={() => addLoobyWorldAsync(selectedItems)}
        //     color="primary"
        //     size="small"
        //     className={`w-full !px-10 mb-2 rounded-2xl ${
        //       isTrue ? "!bg-[#dc3545]" : "!bg-[#28a745]"
        //     }`}
        //   >
        //     {isTrue ? "UnPublish" : "Publish"}
        //   </Button>
        // );
        // }
      });
      setUsersData(filteredData);
    }
  };
  useMemo(async () => {
    setUsersData([]);
    await handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, entries, searchTerm]);

  const handleProceed = async (isConfirmed: boolean) => {
    if (!isConfirmed) {
      setUsersData([]);
      await handlePageChange(pageNumber);
      return proceedModalClose();
    }
    if (rowData?.id?.assetId !== null && rowData?.value !== null) {
      const config = {
        index: rowData.value,
      };
      const response = await updateStatusWorldsAsync(
        config,
        rowData.id.assetId
      );
      if (response?.success) {
        await handlePageChange(pageNumber);
        return proceedModalClose();
      } else {
        return proceedModalClose();
      }
    }
  };

  const modalData = (value: String, id: number) => {
    proceedModalOpen();
    setRowData((prev) => ({
      ...prev,
      value,
      id,
    }));
  };

  const {
    Modal: AddProceedModal,
    open: proceedModalOpen,
    close: proceedModalClose,
  } = useModal(<ProceedModal proceed={handleProceed} />, "");

  const handleOrderNumberChange = (value: string, world_id: string) => {
    // Update orderNumbers state
    setSelectedItems((prevSelectedItems: Item[]) => {
      return prevSelectedItems.map((item) =>
        item.world_id === world_id ? { ...item, index: Number(value) } : item
      );
    });
  };

  const handleDeleteLobbyIndex = async (id: any) => {
    try {
      const response = await deleteStatusWorldsAsync(id);

      if (response?.success) {
        toast.info("Successfully deleted");
        setLoading(true);

        await handlePageChange(pageNumber);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Failed to delete");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred");
    }
  };

  const onCheckboxChange = (item: any) => {
    if (
      item?.lobbyAssetData?.index != null &&
      item?.lobbyAssetData?.index !== undefined
    ) {
      handleDeleteLobbyIndex(Number(item?.lobbyAssetData?.assetId));
      setSelectedItems(
        selectedItems.filter((item1) => item1.world_id !== item.id)
      );
    } else {
      setSelectedItems((prevSelectedItems: any) => {
        const formattedData = {
          world_id: item.id,
          index: orderNumbers[item.id],
          world_name: item.name,
          entity_type: item.entityType,
          creator_type: item.creator ? item.creator : null,
          description: item.description,
          thumbnail: item.thumbnail,
        };

        const isAlreadySelected = prevSelectedItems.some(
          (selectedItem: any) => selectedItem.world_id === item.id
        );

        if (isAlreadySelected) {
          return prevSelectedItems.filter(
            (selectedItem: any) => selectedItem.world_id !== item.id
          );
        } else if (prevSelectedItems.length >= 10) {
          return prevSelectedItems;
        } else {
          return [...prevSelectedItems, formattedData];
        }
      });
    }
  };
  useEffect(() => {
    const itemsWithLobbyIndex = usersData
      .filter((item: any) => item?.lobbyAssetData?.index != null)
      .map((item: any) => {
        let newItem = { ...item, world_id: item.id };
        delete newItem.id;
        return newItem;
      });

    setSelectedItems((prevSelectedItems) => {
      const prevSelectedIds = new Set(
        prevSelectedItems.map((item: any) => item.world_id)
      );

      const newItems = itemsWithLobbyIndex.filter(
        (item: any) => !prevSelectedIds.has(item.world_id)
      );

      return [...prevSelectedItems, ...newItems];
    });
  }, [usersData]);

  useEffect(() => {
    const itemsToAdd = selectedItems.filter(
      (item) => !item.hasOwnProperty("lobbyAssetData")
    );

    setLobbyIndexPayload((prevState: any) => {
      const selectedWorldIds = new Set(
        selectedItems.map((item) => item.world_id)
      );

      const newItemsToAdd = itemsToAdd.filter(
        (item) =>
          !prevState.some(
            (prevStateItem: any) => prevStateItem.world_id === item.world_id
          )
      );

      const updatedPrevState = prevState.filter((item: any) =>
        selectedWorldIds.has(item.world_id)
      );

      const updatedPrevStateWithNewIndex = updatedPrevState.map(
        (prevStateItem: any) => {
          const selectedItem = selectedItems.find(
            (selectedItem) => selectedItem.world_id === prevStateItem.world_id
          );
          if (selectedItem) {
            return {
              ...prevStateItem,
              index: selectedItem.index,
            };
          }
          return prevStateItem;
        }
      );

      return [...updatedPrevStateWithNewIndex, ...newItemsToAdd];
    });
  }, [selectedItems]);

  useEffect(() => {
    const valid = selectedItems.every((item: any) => {
      const orderValue = item.orderValue;
      return orderValue >= 0 && orderValue <= 10;
    });

    // if (selectedItems.length == 10) {
    //   setIsValid(true);
    // } else {
    //   setIsValid(false);
    // }
  }, [selectedItems]);

  const handleSubmit = async () => {
    if (selectedItems.length > 10) return false;
    // const updatedSelectedItems = selectedItems.map((item: any) => ({
    //   ...item,
    //   index: item.index !== undefined && item.index,
    //   lobby_index: item?.index !== undefined ? item?.index ,
    // }));

    // const lobbyAssetData = selectedItems.filter((obj) => obj.lobbyAssetData);
    // console.log(lobbyAssetData.length, "lobbyAssetData");
    // if (lobbyAssetData.length > 0) {
    //   return toast.error("Enter Order");
    // }
    if (lobbyIndexPayload.some(obj => obj.index === 0)) {
      return toast.error("Enter Order with a valid index (excluding 0)");
    }
    if (
      lobbyIndexPayload.some(
        (obj: any) => obj.index == null || obj?.index == null
      )
    ) {
      return toast.error("Enter Order");
    }
    if (
      lobbyIndexPayload.some(
        (obj, index, array) =>
          array.findIndex((o) => o.index === obj.index) !== index
      )
    ) {
      return toast.error("Enter Order with unique indices");
    }

    // Call API with the orderValues
    // try {
    const repsonse = await addLoobyWorldAsync(lobbyIndexPayload);
    if (repsonse?.success) {
      toast.info("Succes");
      handlePageChange(pageNumber);
      setLobbyIndexPayload([]);
      setSelectedItems([]);
    } else {
      toast.error(repsonse?.msg);
      // setLobbyIndexPayload([]);
      // setSelectedItems([]);
      // const value:any =document.getElementById("lobbyNumber")
      // value.value = "";
    }
  };
  const handleSet = async (data: FieldValues) => {
    try {
      const res = await addTextLooby?.(data);
      if (res?.success) {
        toast.info("Text added successfully")
        handlePageChange(pageNumber);
        close?.();
      }
    } catch (error) {}
  };
  const {
    Modal: AddModal,
    open,
    close,
  } = useModal(<AddTextModaL submit={handleSet} />, "Add Text");

  return (
    <main className="flex-grow w-full h-[264px] bg-blue-900 p-6">
      <Navbar options={settingOptions} />
      <div className="bg-white p-4 shadow-md ">
        <div className="justify-between flex">
          {/* <div>
            <p className="text-[16px] mb-3">series</p>
          </div> */}
          <div style={{ marginLeft: "auto" }}>
            <Button
              onClick={() => open()}
              color="primary"
              size="small"
              className="w-full !px-10 !bg-blue-600"
            >
              Add Text
            </Button>
          </div>
        </div>
        <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />

        <Table
          tableDate={usersData}
          searchItem={searchTerm}
          entries={entries}
          keysToFilter={tableData}
          tableHeaderRow={tableHeaderRow}
          totalDataCount={totalDataCount}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          isLoading={isLoading}
          selectedObjects={selectedItems}
          onCheckboxChange={onCheckboxChange}
          handleOrderNumberChange={handleOrderNumberChange}
          isValid={isValid}
          handleSubmit={handleSubmit}
          handleDelteLobbyIndex={handleDeleteLobbyIndex}
          modalData={modalData}
        />

        <AddProceedModal />
        <AddModal />
      </div>
    </main>
  );
};
