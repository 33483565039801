export const tableData = [
  "name",
  "version",
  "group",
  "createdAt",
  "delete",
  "id",
];
export const tableHeaderRow = [
  "Name",
  "Version",
  "Group",
  "Created At",
  "Delete",
];
