export const tableData = [
  "iconLink",
  "name",
  "price",
  "isPurchased",
  "version",
  "createdAt",
  "Active",
  "id",
  "delete",
  "edit"
];

export const obj = {
  "isDeleted": "Active",
};

export const addItemData = [
  {
    name:"name",
    type:"text",
    placeholder:"enter name",
    className:"w-full dark:text-neutral-400"
  },
  {
    name:"name",
    type:"text",
    placeholder:"enter name",
    className:"-mx-1 flex flex-wrap"
  },
  {
    name:"name",
    type:"text",
    placeholder:"enter name",
    className:"w-full dark:text-neutral-400"
  },
  {
    name:"name",
    type:"text",
    placeholder:"enter name",
    className:"w-full dark:text-neutral-400"
  },{
    name:"name",
    type:"text",
    placeholder:"enter name",
    className:"w-full dark:text-neutral-400"
  },
]
export const tableHeaderRow = ["Icon","Name","Price","Purchased","Version","Created At","Active","Delete","Edit"]
