import { Button } from "../../../Atoms";
import { FormInput as Input } from "../../../Molecules";
import { FieldValues, useForm } from "react-hook-form";
import { AddSetProps } from "./interface";
import React from "react";

export const AddTextModaL = ({submit}:AddSetProps) => {
    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
      } = useForm();
      const handleAdd = (data: FieldValues) => {
        const { descriptionText ,descriptionTextJP} = data;
        const config ={
          descriptionText :[descriptionText,descriptionTextJP]
        }
              submit?.(config)
      }
    return  <>
    <form onSubmit={handleSubmit(handleAdd)}>
    <div className="mb-6 w-full px-4 ">
      <Input
        className="w-full dark:text-neutral-400"
        control={control}
        error={errors?.name?.message?.toString()}
        label="Enter JP Description"
        placeholder="Enter Jp description"
        name="descriptionText"
        required
        rules={{
          required: { message: "name can not be empty", value: true },
        }}
        type="text"
        // maxLength={20}
      />
      
        <Input
        className="w-full dark:text-neutral-400"
        control={control}
        error={errors?.name?.message?.toString()}
        label="Enter Eng description"
        placeholder="Enter Eng description"
        name="descriptionTextJP"
        required
        rules={{
          required: { message: "name can not be empty", value: true },
        }}
        type="text"
        // maxLength={20}
      />
    </div>
    <div className="mb-6 w-full px-4 py-4">
      <Button
        color="primary"
        size="small"
        variant="solid"
        type="submit"
        className="w-full !px-10"
      >
        Save Changes
      </Button>
    </div>
    </form>
  </>
}