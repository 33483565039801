import { useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { TableHeader } from '../../Molecules';
import { tableData, tableHeaderRow } from './utils';
import { useUser } from '../../../hooks/';
import { FilteredTableData, settingOptions } from '../../../utils';
import noavatar from '../../../assets/images/noavatar.jpg';
import { Typography } from '../../Atoms';

export const DashboardHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState(10);
  const [usersData, setUsersData] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const { userAsync } = useUser();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, entries: number) => {
    try {
      return await userAsync(data, entries);
    } catch (err) {}
  };

  const handlePageChange = async (pageNumber: number) => {
    setLoading(true);
    const data = await getUserData(pageNumber, entries);
    if (data) {
      setLoading(false);
      setTotalDataCount(data.count);
      setPageNumber(pageNumber);
      const filteredData = await FilteredTableData(data.data, tableData);
      filteredData.forEach((item) => {
        // eslint-disable-next-line jsx-a11y/alt-text
        item.avatar = (
          <img
            className='rounded-full w-[50px] h-[50px] mx-auto bg-blue-500 w-16 h-16 m-n1'
            src={item.avatar ?? noavatar}
          />
        );
        item.isVerified = (
          <p className='  py-2 !bg-[#28a745] text-white font-medium rounded-2xl'>
            {item.isVerified ? 'Active' : 'nonActive'}
          </p>
        );
      });
      setUsersData(filteredData);
    }
  };
  useMemo(async () => {
    handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, entries]);
  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900 p-6'>
      <Navbar options={settingOptions} />
      <div className='mx-[80px] mt-7'>
        <div className='bg-white py-4 shadow-md px-2'>
          <p className='text-[16px] px-4 mb-3 text-[#3F4674] font-bold'>Users</p>
          <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />
          <Table
            tableDate={usersData}
            searchItem={searchTerm}
            entries={entries}
            keysToFilter={tableData}
            totalDataCount={totalDataCount}
            pageNumber={pageNumber}
            onPageChange={handlePageChange}
            tableHeaderRow={tableHeaderRow}
            isLoading={isLoading}
          />
        </div>
      </div>
    </main>
  );
};
