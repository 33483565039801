import {AllEventsService} from "../../api-services"

export const useAllEvents = () => {
    const allEventsAsync = (pageSize: number,pageNumber:number) => AllEventsService.getAllEvents(pageSize,pageNumber)
    const allUpdateEventsAsync = (payload:any) => AllEventsService.updateAllEvents(payload)
    const allUpdateEventsOrderAsync = (payload:any) => AllEventsService.reOrderEvents(payload)    
    return {
        allEventsAsync,
        allUpdateEventsAsync,
        allUpdateEventsOrderAsync
      } 
}