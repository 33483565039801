import { SideBarMenuProps } from "./interface";
import { useSelector } from "react-redux";
import type { RootState } from "../../../store";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getUserName } from "../../../utils";
import { HomeIcon, XanaIcon } from "../../Atoms";

const imagURL =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfEYotK9xtcsA57ZfZ88MZxiyvTVX1t0zVZtL7xve-LQ&s";

export const Sidebar = ({
  sideBarMenu,
  profileName = "",
  profileImageURl = imagURL,
  sideBarHeader = "Xana Admin Panel",
}: SideBarMenuProps) => {
  const sidebar = useSelector((state: RootState) => state.sideBar.value);
  const isShow: boolean = useMemo(() => sidebar, [sidebar]);
  const location = useLocation();
  const userName = getUserName();
  const pathname = location.pathname;

  const checkImage = (location: string,path:string) => {
    if (path === pathname.replace("/", "")) {
      return true;
    }
  };
  return (
    // <aside className={`bg-neutral-700 w-[264px] h-full ${isShow&& 'hidden'}`}>
    //   <div className="flex items-center justify-center h-16 bg-[#153d77] ">
    //     <h1 className="text-white text-2xl font-bold">{sideBarHeader}</h1>
    //   </div>
    //   <div className="text-center my-8">
    //     <figure className="rounded-full h-20 w-20 m-auto">
    //       <img
    //         src={profileImageURl}
    //         alt="profile"
    //         className="w-full h-full rounded-full"
    //       />
    //     </figure>
    //     <h6 className="mt-2">{userName}</h6>
    //   </div>
    //   <nav className="p-4">
    //     <ul className="space-y-2">
    //     {sideBarMenu?.map(({ title, route }) => (
    //         <li key={route}>
    //           <a
    //             href={route}
    //             className="text-black hover:text-gray-300 transition duration-300"
    //           >
    //             {title}
    //           </a>
    //         </li>
    //       ))}
    //     </ul>
    //   </nav>
    // </aside>

    <aside className={`${isShow && "hidden"} bg-white`}>
      <div className="w-screen md:w-250 bg-white pt-[60px]">
        <figure className="flex justify-center mb-[100px]">
          <XanaIcon />
        </figure>

        <nav>
          <ul>
            {sideBarMenu?.map(({ title, route, fillIcon, Icon }) => (
              <li
                className={`pl-[28px] py-2 transition-300 hover:bg-gray-100 ${
                  pathname.replace("/", "") == route && "bg-gray-100"
                }`}
              >
                <a
                  href={route}
                  key={route}
                  className="flex gap-2 items-center text-black"
                >
                  {checkImage(pathname,route) ? (
                    <img alt="alt" src={fillIcon} />
                  ) : (
                    <img alt="alt" src={Icon} />
                  )}
                  <span>{title}</span>
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};
