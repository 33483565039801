import { Button } from "flowbite-react";
import { DataItem, TableProps } from "./interface";
import { useMemo, useState } from "react";
import { getSpinnerSize, getSpinnerStokeColor } from "../../Atoms/Button/utils";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Spinner } from "../../Atoms";
import { useLocation } from "react-router-dom";

export const Table = ({
  tableDate = [],

  tableSetID,
  searchItem = "",
  entries = 10,
  keysToFilter,
  totalDataCount,
  pageNumber,
  searchBy = "name",
  tableHeaderRow = [],
  onPageChange = () => false,
  onDeleteRow = () => false,
  isLoading = false,
  selectedObjects,
  onCheckboxChange,
  handleOrderNumberChange,
  isValid,
  handleSubmit,
  handleDelteLobbyIndex,
  modalData,
}: TableProps) => {
  const location = useLocation();
  const { pathname } = location;
  const [tableData, setTableData] = useState<DataItem[]>(tableDate);
  const [showMore, setShowMore] = useState<any>({});
  const tableHeaderData = useMemo(() => {
    return tableHeaderRow.length ? tableHeaderRow : keysToFilter;
  }, [keysToFilter, tableHeaderRow]);

  useMemo(() => {
    if (!searchItem) {
      setTableData(tableDate ? tableDate : ([] as DataItem[]));
    } else {
      if (tableDate.length > 0 && searchBy) {
        setTableData(
          tableDate.filter(
            (item) =>
              item[searchBy] &&
              item[searchBy].toLowerCase().includes(searchItem?.toLowerCase())
          )
        );
      } else setTableData([] as DataItem[]);
    }
  }, [searchItem, tableDate, searchBy]);

  const spinnerClasses = [
    getSpinnerStokeColor("neon"),
    getSpinnerSize("large"),
  ].join(" ");

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center min-h-screen">
          <Spinner className={spinnerClasses} />
        </div>
      ) : (
        <div className="relative overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="border-y border-[#efefef] h-[40px]">
              <tr>
                {tableHeaderData?.map((item, index) => {
                  if (
                    item === "status" ||
                    item === "id" ||
                    item === "approvalStatus" ||
                    item === "rowId"
                  )
                    return null;
                  return (
                    <th key={index} className="text-[#3F4674]">
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData.slice(0, entries).map((item, rowIndex) => (
                <tr key={rowIndex} className="border-b border-[#efefef]">
                  {keysToFilter?.map((value, index) => {
                    if (
                      value === "id" ||
                      value === "approvalStatus" ||
                      value === "rowId"
                    )
                      return null;

                    return (
                      <td
                        className={`py-2 px-4 text-center text-sm`}
                        style={
                          value === "name"
                            ? {
                                lineBreak: "anywhere",
                              }
                            : {}
                        }
                        key={index}
                      >
                        {value === "checkbox" ? (
                          <>
                            <input
                              type="checkbox"
                              onChange={() => onCheckboxChange(item)}
                              checked={selectedObjects.some(
                                (selectedItem: any) =>
                                  selectedItem.world_id === item.id
                              )}
                            />
                          </>
                        ) : value === "lobbyAssetData" &&
                          pathname === "/LobbyWorlds" ? (
                          item?.lobbyAssetData?.index != null &&
                          item?.lobbyAssetData?.index !== undefined ? (
                            <>
                              <input
                                onBlur={(e) =>
                                  modalData(e.target.value, item?.lobbyAssetData,)
                                }
                                type="number"
                                defaultValue={
                                  item?.lobbyAssetData?.index !== null &&
                                  item?.lobbyAssetData?.index !== undefined
                                    ? item?.lobbyAssetData?.index
                                    : selectedObjects?.find(
                                        (selectedItem: any) =>
                                          selectedItem?.world_id === item?.id
                                      )?.index || 0
                                }                                className="w-24 h-8 text-sm px-2 py-1 border rounded"
                                min={0}
                                onKeyDown={(e) => {
                                  if (e.key === "-" || e.key === ".") {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </>
                          ) : (
                            <>                              <input
                                onChange={(e) => {
                                  const value = parseInt(e.target.value, 10);
                                  if (value > 10) {
                                    e.target.value = "10";
                                  }
                                  handleOrderNumberChange(
                                    e.target.value,
                                    item.id
                                  );
                                }}
                                type="number"
                                required
                                defaultValue={
                                  item?.lobbyAssetData?.index !== null &&
                                  item?.lobbyAssetData?.index !== undefined
                                    ? item?.lobbyAssetData?.index
                                    : selectedObjects?.find(
                                        (selectedItem: any) =>
                                          selectedItem?.world_id === item?.id
                                      )?.index || 0
                                }
                                className={`w-24 h-8 text-sm px-2 py-1 border rounded ${
                                  !selectedObjects?.some(
                                    (selectedItem: any) =>
                                      selectedItem.world_id === item.id
                                  )
                                    ? "bg-gray-200"
                                    : ""
                                }`}
                                min={0}
                                max={10}
                                disabled={
                                  !selectedObjects?.some(
                                    (selectedItem: any) =>
                                      selectedItem.world_id === item.id
                                  )
                                }
                                onKeyDown={(e) => {
                                  if (
                                    ["-", ".", "e", "+", ","].includes(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                id="lobbyNumber"
                              />
                            </>
                          )
                        ) : value === "thumbnail" &&
                          pathname === "/LobbyWorlds" ? (
                          <div className="flex justify-center items-center">
                            <img
                              className="rounded-full bg-blue-500 w-16 h-16 m-n1"
                              src={
                                item.thumbnail
                                  ? item.thumbnail
                                  : "path/to/your/defaultThumbnail.jpg"
                              }
                              alt="Thumbnail"
                            />
                          </div>
                        ) : typeof item[value] === "string" &&
                          item[value].length > 60 ? (
                          <div className="flex flex-col items-center justify-center">
                            <span className="mb-1">
                              {showMore[rowIndex]
                                ? item[value]
                                : `${item[value].substring(0, 40)}...`}
                            </span>
                            <button
                              onClick={() =>
                                setShowMore((prev: any) => ({
                                  ...prev,
                                  [rowIndex]: !prev[rowIndex],
                                }))
                              }
                              className="bg-black text-white py-1 px-2 rounded-full transition duration-300 ease-in-out hover:bg-gray-700 active:bg-gray-800 flex items-center space-x-2 text-xs"
                            >
                              {showMore[rowIndex] ? "Show Less" : "Show More"}
                              {showMore[rowIndex] ? (
                                <FiChevronUp className="self-center" />
                              ) : (
                                <FiChevronDown className="self-center" />
                              )}
                            </button>
                          </div>
                        ) : item[value] === true || item[value] === false ? (
                          String(item[value])
                        ) : (
                          item[value] ?? ""
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
              {tableSetID && <tr></tr>}
            </tbody>
          </table>
        </div>
      )}

      {pathname === "/LobbyWorlds" && (
        <div className="flex justify-end py-2">
          <button
            className={`font-bold py-2 px-4 rounded-lg float-right ${
         
                 "bg-blue-500 hover:bg-blue-700 text-white"
             
            }`}
            // disabled={!isValid}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
        </div>
      )}

      <div className="flex justify-between mt-2">
        {!searchItem && totalDataCount && pageNumber && (
          <>
            <div>{`Showing ${
              pageNumber === 1 ? 1 : (pageNumber - 1) * entries
            } to ${
              pageNumber === 1
                ? tableDate.length
                : (pageNumber - 1) * entries + tableDate.length
            } of ${totalDataCount} entries`}</div>
            <div className="border border-gray-300">
              <button
                className="disabled:opacity-75 border-r border-gray-300 p-2"
                disabled={pageNumber <= 1}
                onClick={() => onPageChange(pageNumber - 1)}
              >
                Previous
              </button>

              {Array.from(Array(Math.ceil(totalDataCount / entries)).keys())
                .slice(
                  Math.max(0, pageNumber - 3),
                  Math.min(pageNumber + 2, Math.ceil(totalDataCount / entries))
                )
                .map((value, index) => (
                  <button
                    key={index}
                    className={`${
                      pageNumber === value + 1 && "bg-[#153d77] text-white"
                    } border-r border-gray-300 p-2`}
                    onClick={() => onPageChange(value + 1)}
                  >
                    {value + 1}
                  </button>
                ))}
              <button
                className="p-2 disabled:opacity-75"
                disabled={Boolean(
                  (pageNumber - 1) * entries + tableDate.length ===
                    totalDataCount
                )}
                onClick={() => onPageChange(pageNumber + 1)}
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
