export const setNames = (data: any) => {
  const featuresSetsRelations = data.map((item: any) => {
    const enabledSet = item.featuresSetsRelations.map((relation: any) => {
      return {
        [relation.set.name.replace(/[\s-]/g, "")]: relation.isEnabled,
      };
    });
    return {
      name: item.name,
      id: item.id,
      ...Object.assign({}, ...enabledSet),
    };
  });
  return featuresSetsRelations;
};
export const setId = (data: any) => {
  const featuresSetsRelations = data.map((item: any) => {
    const enabledSet = item.featuresSetsRelations.map((relation: any) => ({
      [relation.set.name.replace(/[\s-]/g, "")]: relation.setId,
    }));
    return enabledSet;
  });
  return featuresSetsRelations;
};
