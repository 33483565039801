import { Button, Select, Typography } from '../../../Atoms';
import { FormInput as Input } from '../..';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { dataForm, version } from './utils';
import { AddEnvironmentModalProps } from './interface';
export const AddAnimationsModal = ({ save }: AddEnvironmentModalProps) => {
  const [selectData, setSelectData] = useState({
    version: version[0],
  });
  const { handleSubmit, control, register } = useForm();
  const onSubmit = (data: any) => {
    const version = selectData?.version.value;
    const obj = { version };
    data = {
      ...data,
      android_file: data['android_file'][0],
      ios_file: data['ios_file'][0],
      universal_file: data['universal_file'][0],
      thumbnail: data['thumbnail'][0],
      webgl_file: data['webgl_file'][0],
      macos_file: data['macos_file'][0],
      standalone_file: data['standalone_file'][0],
    };
    const objData = { ...data, ...obj };
    save?.(dataForm(objData));
  };
  const labelTextClassName = ['mb-1 !text-caption !leading-caption !tracking-caption !font-normal   !text-[12px'].join(
    ' '
  );

  return (
    <form
      className='md:!w-[650px] max-h-[500px] overflow-auto overflow-y-auto overflow-x-hidden px-2'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='mb-6 px-4 '>
        <Input
          required
          className='w-full dark:text-neutral-400'
          control={control}
          label='Animations Name'
          labelClassName='!text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
          placeholder='name'
          name='name'
          maxLength={15}
          type='text'
        />
      </div>
      <div className='mb-6 px-4 '>
        <Input
          required
          className='w-full dark:text-neutral-400'
          control={control}
          label='Animations Group'
          placeholder='Animations Group'
          labelClassName='!text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
          maxLength={15}
          name='group'
          type='text'
        />
      </div>
      <div className='-mx-1  flex flex-wrap '>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'Android File'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('android_file')}
          />
        </div>
        <div className='w-full mb-4 px-4 sm:w-1/2 md:w-1/2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'Ios File'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('ios_file')}
          />
        </div>
        <div className='w-full mb-4 px-4 sm:w-1/2 md:w-1/2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'Universal File'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('universal_file')}
          />
        </div>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'thumbnail'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('thumbnail')}
          />
        </div>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'Webgl File'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('webgl_file')}
          />
        </div>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'MacOs File'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('macos_file')}
          />
        </div>
        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2 mt-2'>
          <Typography variant='condensed' size='caption' className={labelTextClassName}>
            {'Standalone File'}
            {<span className='font-bold text-[#FF0000]'>*</span>}
          </Typography>
          <input
            required
            className='w-full dark:text-neutral-400'
            type='file'
            accept='image/*'
            {...register('standalone_file')}
          />
        </div>

        <div className='mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2 mt-3'>
          <Input
            required
            className='w-full dark:text-neutral-400'
            labelClassName='!text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
            control={control}
            label='Description'
            placeholder='Description'
            name='description'
            type='text'
            maxLength={30}
          />
        </div>

        <div className='w-full px-4 sm:w-1/2 md:w-1/2 mt-3'>
          <Select
            label='Select Version'
            options={version}
            labelTextClassName=' !text-caption !leading-caption !tracking-caption !font-normal   !text-[12px]'
            slectedClassName='!py-[4px]'
            value={selectData.version}
            setSelectedCollectionAsset={(data) => setSelectData({ ...selectData, version: data })}
          />
        </div>
      </div>

      <div className='mb-6 w-full flex justify-end px-4 py-4'>
        <Button color='primary' size='small' variant='solid' type='submit' className='w-[200px] !px-10'>
          Save change
        </Button>
      </div>
    </form>
  );
};
