import CoreAPIService from "./CoreAPIService";
import { API_ENDPOINTS, checkAccessToken } from "../utils";
import axios from "axios";
const BASE_API_ENDPOINT =
  process.env.REACT_APP_PUBLIC_NETWORK_TYPE === "testnet"
    ? process.env.REACT_APP_PUBLIC_API_TESTNET_BACKEND_URL
    : process.env.REACT_APP_PUBLIC_API_MAINNET_BACKEND_URL;

class LobbyWorldService {
  getAllWorlds = async (pageSize: number, pageNumber: number) => {
    const accessToken = checkAccessToken();
    let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.GETLOBBYWORLDS}/${pageSize}/${pageNumber}`;
    try {
      return axios
        .get(url, { headers: { authorization: accessToken } })
        .then((response) => {
          return response.data;
        });
    } catch (error) {}
  };
  addLobbyAllWorlds = async (payload: any) => {
    const accessToken = checkAccessToken();
    let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.UPDATELOBBYWORLD}`;
    try {
      return axios
        .post(url, payload, { headers: { authorization: accessToken } })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error.response.data;
        });
    } catch (error) {
      return error;
    }
  };
  updateStatusWorlds = async (data: any, id: any) => {
    const accessToken = checkAccessToken();
    let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.UPDATELOBBYWORLDS}/${id}`;
    try {
      return axios
        .put(url, data, { 
          headers: { 
            'authorization': accessToken,
            'Content-Type': 'application/json', // Set the content type to JSON
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error.response.data;
        });
    } catch (error) {
      return error;
    }
  };

  addText = async (payload: any) => {
    const accessToken = checkAccessToken();
    let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.ADDTEXT}`;
    try {
      return axios
        .post(url, payload, { headers: { authorization: accessToken } })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error.response.data;
        });
    } catch (error) {
      return error;
    }
  };

  deleteLobbyWorlds = async (id: number) =>
    CoreAPIService.delete<any>(
      `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.DELETELOBBYWORLD}/${id}`
    );
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new LobbyWorldService();
