import React, { useMemo, useEffect } from "react";
import { Button, Select, Typography } from "../../../Atoms";
import { FormInput as Input } from "../..";
import { useForm } from "react-hook-form";
import { Checkbox } from "../../../Atoms/Checkbox";
import { useState } from "react";
import { category, dataForm, gender, subcategory, version } from "./utils";
import { AddItemModalProps } from "./interface";
export const AddItemModal = ({ save }: AddItemModalProps) => {
  const [selectData, setSelectData] = useState({
    category: category[0],
    subCategory: subcategory[0],
    gender: gender[0],
    version: version[0],
  });
  const [checkBoxData, setCheckBoxData] = useState({
    paid: false,
    purchased: false,
    occupied: false,
    favorite: false,
  });
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    const assetGender = selectData?.gender.value;
    const subCategoryId = selectData?.subCategory.value;
    const categoryId = selectData?.category.value;
    const version = selectData?.version.value;

    const isFavourite = checkBoxData?.favorite;
    const isPaid = checkBoxData?.paid;
    const isPurchased = checkBoxData?.purchased;
    const isOccupied = checkBoxData?.occupied;
    const obj = {
      assetGender,
      subCategoryId,
      categoryId,
      version,
      isFavourite,
      isPaid,
      isPurchased,
      isOccupied,
    };
    data = {
      ...data,
      assetLinkAndroid: data["assetLinkAndroid"][0],
      assetLinkIos: data["assetLinkIos"][0],
      assetLinkMac: data["assetLinkMac"][0],
      assetLinkWindows: data["assetLinkWindows"][0],
      iconLink: data["iconLink"][0],
    };
    const objData = { ...data, ...obj };
    save?.(dataForm(objData));
  };
  const labelTextClassName = [
    "mb-1 font-semibold text-neutral-100 dark:text-neutral-600",
  ].join(" ");

  const { price } = watch();

  useMemo(() => {
    const numericValue = parseFloat(price);

    if (!isNaN(numericValue) && numericValue >= 0) {
      setValue("price", numericValue);
    } else {
      setValue("price", "");
    }
  }, [price]);

  return (
    <form
      className="md:!w-[650px] max-h-[500px] overflow-auto overflow-y-auto overflow-x-hidden"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-6 px-4 ">
        <Input
          required
          className="w-full dark:text-neutral-400"
          control={control}
          error={errors?.name?.message?.toString()}
          label="name"
          placeholder="name"
          name="name"
          type="text"
          maxLength={35}

        />
      </div>
      <div className="-mx-1  flex flex-wrap ">
        <div className="mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2">
          <Typography
            variant="condensed"
            size="caption"
            className={labelTextClassName}
          >
            {"assetLinkAndroid"}
            {<span className="font-bold text-[#FF0000]">*</span>}
          </Typography>
          <input
            required
            className="w-full dark:text-neutral-400"
            type="file"
            accept="image/*"
            {...register("assetLinkAndroid")}
          />
        </div>
        <div className="w-full mb-4 px-4 sm:w-1/2 md:w-1/2">
          <Typography
            variant="condensed"
            size="caption"
            className={labelTextClassName}
          >
            {"assetLinkIos"}
            {<span className="font-bold text-[#FF0000]">*</span>}
          </Typography>
          <input
            required
            className="w-full dark:text-neutral-400"
            type="file"
            accept="image/*"
            {...register("assetLinkIos")}
          />
        </div>
        <div className="mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2">
          <Typography
            variant="condensed"
            size="caption"
            className={labelTextClassName}
          >
            {"assetLinkWindows"}
            {<span className="font-bold text-[#FF0000]">*</span>}
          </Typography>
          <input
            required
            className="w-full dark:text-neutral-400"
            type="file"
            accept="image/*"
            {...register("assetLinkWindows")}
          />
        </div>
        <div className="w-full mb-4 px-4 sm:w-1/2 md:w-1/2">
          <Typography
            variant="condensed"
            size="caption"
            className={labelTextClassName}
          >
            {"assetLinkMac"}
            {<span className="font-bold text-[#FF0000]">*</span>}
          </Typography>
          <input
            required
            className="w-full dark:text-neutral-400"
            type="file"
            accept="image/*"
            {...register("assetLinkMac")}
          />
        </div>
        <div className="mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2">
          <Typography
            variant="condensed"
            size="caption"
            className={labelTextClassName}
          >
            {"iconLink"}
            {<span className="font-bold text-[#FF0000]">*</span>}
          </Typography>
          <input
            required
            className="w-full dark:text-neutral-400"
            type="file"
            accept="image/*"
            {...register("iconLink")}
          />
        </div>
        <div className="w-full mb-4 px-4 sm:w-1/2 md:w-1/2">
          <Select
            label="Select Category"
            options={category}
            value={selectData.category}
            setSelectedCollectionAsset={(data) =>
              setSelectData({ ...selectData, category: data })
            }
          />
        </div>
        <div className="w-full mb-4 px-4 sm:w-1/2 md:w-1/2">
          <Select
            label="Select Subcategory"
            options={subcategory}
            value={selectData.subCategory}
            setSelectedCollectionAsset={(data) =>
              setSelectData({ ...selectData, subCategory: data })
            }
          />
        </div>
        <div className="w-full px-4 sm:w-1/2 md:w-1/2">
          <Select
            label="Select Version"
            options={version}
            value={selectData.version}
            setSelectedCollectionAsset={(data) =>
              setSelectData({ ...selectData, version: data })
            }
          />
        </div>
      </div>
      <div className="w-full mb-4 px-4 sm:w-1/2 md:w-1/2">
        <Select
          label="Select Gender"
          options={gender}
          setSelectedCollectionAsset={(data) =>
            setSelectData({ ...selectData, gender: data })
          }
          value={selectData.gender}
        />
      </div>
      <div className="w-full px-4 sm:w-1/2 md:w-full">
        <Input
          required
          className="w-full dark:text-neutral-400"
          control={control}
          error={errors?.Price?.message?.toString()}
          label="Price"
          name="price"
          placeholder="Price"
          type="number"
          value={price}
          min={0}
        />
      </div>
      <div className="mb-6 flex gap-[100px] w-full px-4 py-4">
        <div>
          <Checkbox
            value={checkBoxData.paid}
            className="mb-4"
            label="Paid"
            onChange={(value) =>
              setCheckBoxData((prev) => ({ ...prev, paid: value }))
            }
          />
          <Checkbox
            value={checkBoxData.purchased}
            className="mb-4"
            label="Purchased"
            onChange={(value) =>
              setCheckBoxData((prev) => ({ ...prev, purchased: value }))
            }
          />
        </div>
        <div>
          <Checkbox
            value={checkBoxData.occupied}
            className="mb-4"
            label="Occupied"
            onChange={(value) =>
              setCheckBoxData((prev) => ({ ...prev, occupied: value }))
            }
          />
          <Checkbox
            value={checkBoxData.favorite}
            className="mb-4"
            label="Favorite"
            onChange={(value) =>
              setCheckBoxData((prev) => ({ ...prev, favorite: value }))
            }
          />
        </div>
      </div>
      <div className="mb-6 w-full flex justify-end px-4 py-4">
        <Button
          color="primary"
          size="small"
          variant="solid"
          type="submit"
          className="w-[200px] !px-10"
        >
          Save change
        </Button>
      </div>
    </form>
  );
};
