import { ReactionsHeader,Sidebar } from "../..";
import { sideBarMenu } from "../../../utils";

export const ReactionsTemplate = () => {
return(
   <div className="flex h-screen">
    <Sidebar sideBarMenu={sideBarMenu} />
    <ReactionsHeader />
  </div>
)
};
