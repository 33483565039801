import { checkAccessToken } from "./storage";

export const FilteredTableData = (data: any, keysToFilter: any) => {
  const filteredData: Partial<any>[] = data.map((obj: any) =>
    keysToFilter.reduce((filteredObj: any, key: any) => {
      if (obj[key] !== null || obj[key] === null ) {
        filteredObj[key] = obj[key];
      }
      return filteredObj;
    }, {} as Partial<any>)
  );
  return filteredData;
};

export const convertTimeZone = (date: string) => {
  const dateObject = new Date(date);
  return dateObject.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "long",
  });
};
export const getAuth = () => {
  const persistedData = localStorage.getItem("persist:root");
  const token = checkAccessToken()
  if (!persistedData) return false;
  const parsedPersistedData = JSON?.parse(persistedData as string);
  const authData = JSON?.parse(parsedPersistedData.auth);
  return Boolean(token);
};

export const changeKeyNameOfObject = (arrayObject: any, keyMapping: any) => {
  var updatedArray = arrayObject.map(function (obj: any) {
    var updatedObj: any = {};
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        var updatedKey = keyMapping[key] || key; // Use the mapped key if it exists, otherwise use the original key
        updatedObj[updatedKey] = obj[key];
      }
    }

    return updatedObj;
  });
  return updatedArray
};
