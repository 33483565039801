import { SideBarMenuProps } from "./inerface";
import { useDispatch, useSelector } from "react-redux";
import { analyticsFilterValue, type RootState } from "../../../../store";
import { useMemo } from "react";
import { AnalyticsIcon } from "../../../Atoms";
import { DashboardIcon } from "../../../Atoms";
import { getUserName } from "../../../../utils";

const imagURL =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfEYotK9xtcsA57ZfZ88MZxiyvTVX1t0zVZtL7xve-LQ&s";

export const AnalyticsSideBar = ({
  sideBarMenu,
  profileName = "",
  profileImageURl = imagURL,
  sideBarHeader = "Xana Admin Panel",
}: SideBarMenuProps) => {
  const sidebar = useSelector((state: RootState) => state.sideBar.value);
  const isShow: boolean = useMemo(() => sidebar, [sidebar]);
  const userName = getUserName();
  const dispatch = useDispatch();
  const handleAnalytic = (value: string) => {
    dispatch(analyticsFilterValue(value));
  };
  return (
    <aside className={`bg-neutral-700 w-[264px] h-full ${isShow && "hidden"}`}>
      <div className="flex items-center justify-center h-16 bg-[#153d77] ">
        <h1 className="text-white text-2xl font-bold">{sideBarHeader}</h1>
      </div>
      <div className="text-center my-8">
        <figure className="rounded-full h-20 w-20 m-auto">
          <img
            src={profileImageURl}
            alt="profile"
            className="w-full h-full rounded-full"
          />
        </figure>
        <h6 className="mt-2">{userName}</h6>
      </div>
      <div className="flex flex-row pl-12 my-4 gap-2">
        <DashboardIcon />
        <a
          href={"/users"}
          className="text-black hover:text-gray-300 transition duration-300"
        >
          Dashboard
        </a>
      </div>
      <nav className="p-4">
        <ul className="space-y-2">
          {/* {sideBarMenu?.map((item: any, index) => ( */}
          <li
            className="flex gap-2 pl-3 text-sm"
            onClick={() => handleAnalytic("World")}
          >
            <AnalyticsIcon /> Analytics - World
          </li>

          <li
            className="flex gap-2 pl-3 text-sm"
            onClick={() => handleAnalytic("User")}
          >
            {/* {item.payload ? `Analytics - ${item.payload}` : ""} */}
            <AnalyticsIcon /> Analytics - User
          </li>
          {/* ))} */}
        </ul>
      </nav>
    </aside>
  );
};
