import { ItemHeader,Sidebar } from "../../../design-systems";
import { sideBarMenu } from "../../../utils";

export const ItemsTemplate = () => {
return(
   <div className="flex h-screen">
    <Sidebar sideBarMenu={sideBarMenu} />
    <ItemHeader />
  </div>
)
};
