import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HamburgerIcon, SettingIcon } from '../../Atoms';
import { NavbarProps, Options } from './interface';
import { useDispatch } from 'react-redux';
import { isShow } from '../../../store/reducers/sidebarSlice';
// import { disableAccessToken } from "../../../utils";
import { Navigate } from 'react-router-dom';
import { logout } from '../../../store/reducers/';

import { disableAccessToken, removeUserName } from '../../../utils';
export const Navbar = ({ options = [] }: NavbarProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [isOpen, setIsOpen] = useState(false);
  const [isSideBar, setIsSideBar] = useState(false);

  const handleOptionSelect = (option: Options) => {
    setIsOpen(false);
    if (option.label === 'Logout') {
      // dispatch(logout());
      <Navigate to='/' replace={true} />;
      disableAccessToken();
      removeUserName();
    }
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const sideBarHandler = () => {
    setIsSideBar(!isSideBar);
    dispatch(isShow(!isSideBar));
  };
  return (
    <>
      <div className='flex justify-between items-center mb-[45px] px-[80px] py-[15px] bg-white'>
        <div className='cursor-pointer' onClick={sideBarHandler}>
          <HamburgerIcon stroke={'#000'} />
        </div>
        <div className='relative'>
          <button type='button' onClick={toggleDropdown}>
            <SettingIcon stroke={'#000'} />
          </button>
          {isOpen && (
            <div className='absolute top-[150%] right-0 w-32 mt-1 z-10  bg-white shadow-lg'>
              <ul tabIndex={-1} role='menu' aria-labelledby='dropdown-menu' className='py-1'>
                {options.map((option) => (
                  <li
                    key={option.value}
                    onClick={() => handleOptionSelect(option)}
                    className='cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                    role='menuitem'
                  >
                    <a href={option.value} className='text-black hover:text-gray-300 transition duration-300'>
                      {option.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className='mx-[80px]'>
        <h1 className='text-[26px] font-bold mb-3 text-white capitalize'>{pathname.slice(1)}</h1>
        <ul className='flex items-center gap-2 text-[#3F4674] mb-3'>
          {/* <li>Pages</li>
          <li>{pathname}</li> */}
        </ul>
      </div>
    </>
  );
};
