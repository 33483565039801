import { ChangeEvent, useState } from "react";
import { TableHeaderProps } from "./interface";

export const TableHeader = ({
  onSearch,
  onChangeEntries,
}: TableHeaderProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [entries, setEntries] = useState<number>(10);

  const handleChangeEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(event.target.value);
    onChangeEntries?.(value);
    setEntries(Number(value));
  };
  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch?.(event.target.value);
    setSearchTerm(event.target.value);
  };
  return (
    <div className="flex justify-between mb-7 px-4">
      <div className="text-gray-500 flex gap-2 items-center">
        <label htmlFor="entries">Show</label>
        <select
          id="entries"
          className="px-3 py-1 pr-0 border border-gray-300 rounded-md"
          value={entries}
          onChange={handleChangeEntries}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
        </select>
        <label htmlFor="entries">
          entries
        </label>
      </div>
      {onSearch&&  <div className="relative w-1/3">
        <input
          type="text"
          className="w-full px-4 py-2 border border-gray-300 rounded-md"
          placeholder="Search"
          value={searchTerm}
          onChange={handleChangeSearch}
        />
        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
          <svg
            className="w-4 h-4 text-gray-400"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="11" cy="11" r="8" />
            <path d="M21 21l-4.35-4.35" />
          </svg>
        </span>
      </div>}
    
    </div>
  );
};
