import { Button } from "../../../Atoms";
import { FormInput as Input } from "../..";
import { useForm, FieldValues } from "react-hook-form";
import { AddfeatureProps } from "./interface";

export const AddFeatureModal = ({submit}:AddfeatureProps) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const handleAdd = (data:FieldValues) =>{
    submit?.(data)
  }
  return (
    <>
      <form onSubmit={handleSubmit(handleAdd)}>
        <div className="mb-6 w-full px-4 ">
          <Input
            className="w-full"
            control={control}
            error={errors?.name?.message?.toString()}
            label="Name"
            placeholder="Enter feature name"
            name="name"
            required
            rules={{
              required: {
                message: "feature name can not be empty",
                value: true,
              },
            }}
            type="text"
            maxLength={50}
          />
        </div>
        <div className="mb-6 w-full px-4 py-4">
          <Button
            color="primary"
            size="small"
            variant="solid"
            type="submit"
            className="w-full !px-10"
          >
            Save Changes
          </Button>
        </div>
      </form>
    </>
  );
};
