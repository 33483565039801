import { Navbar } from "../../Organisms";
import { FilteredTableData, OPTIONS } from "../../../utils";
import { Table } from "../../Organisms";
import { CSVIcon, FilterIcon, Typography, useModal } from "../../Atoms";
import { FilterWordModal } from "../../Molecules/Models";
import { useAnalytics } from "../../../hooks";
import { useMemo, useState } from "react";
import { tableData, tableHeaderRow } from "./utils";
import { TableHeader } from "../../Molecules";
import { data } from "../../Templates/AnalyticsTemplate/utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { analyticsFilterHistory } from "../../../store/reducers/";
import { AnalyticsheaderUser } from "./analyticsUser";
export const Analyticsheader = () => {
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);
  const [worldCount, setWorldCount] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [usersData, setUsersData] = useState<any>([]);
  const [entries, setEntries] = useState(10);
  const { getAnalyticsAsync } = useAnalytics();
  const [searchWorld,setSearchWorld]=useState<any>('')
  const heading = useSelector((state: any) => state.analyticsSidebar.heading);
  const {
    Modal: FilterWordonModal,
    open,
    close,
  } = useModal(
    <FilterWordModal save={handleFilterDataItem} />,
    "Filter By World"
  );
  const handlePageChange = async (pageNumber:any,filter?: any,) => {
    try {
      dispatch(analyticsFilterHistory(filter?.world));
      const data = await getAnalyticsAsync(pageNumber,entries,filter);
      close();
      if (data.success) {
        setTotalDataCount(data?.data?.count);
        setPageNumber(pageNumber);
        const filteredData = await FilteredTableData(data.data.rows, tableData);
        setWorldCount(filteredData.length);
        setUsersData(filteredData);
      }
      else {
        setTotalDataCount(0);
        setPageNumber(pageNumber);
        setWorldCount(0);
        setUsersData([]);
      }
    } catch (error) {
      close();
      console.log(error);
    }
  };
  useMemo(async () => {
    handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber,entries]);
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1)
  };

  async function handleFilterDataItem(data: any) {
    setSearchWorld(data)
    try {
      handlePageChange(pageNumber,data);
    } catch (error) {}
  }
  const handleReset=()=>{
    setSearchWorld("")
    handlePageChange(pageNumber)
  }
  return (
    <>
      {heading === "World" ? (
        <main className="flex-grow w-full h-[264px] bg-blue-900 p-6">
          <Navbar options={OPTIONS} />
          <div className="bg-white p-4 shadow-md ">
            <div className="flex justify-between">
              {/* <p className="text-[16px] mb-3">Analytics</p> */}
              <div className="flex align-center items-center w-[373px] h-[42px] bg-neutral-800 border-solid border-2 border-inherit" style={{ marginLeft: 'auto' }}>                <div className="justify-between flex" onClick={() => open()}>
                  <div>
                    <FilterIcon />
                  </div>
                  <div className="w-[325px] pl-2">{searchWorld ? searchWorld?.world || searchWorld?.type  : "Filter" } </div>
                </div>
                <div className="flex items-center h-full border-x w-full" onClick={()=>handleReset()}>
                  <CSVIcon />
                </div>
                <FilterWordonModal />
              </div>
            </div>
            <div className="flex justify-between py-4">
              <div>
                <Typography size="caption" className="text-[16px] mb-3">
                  Accessed Worlds
                </Typography>
              </div>
              <div>
                <Typography size="caption">
                  Total No. of Worlds: {worldCount}
                </Typography>
              </div>
            </div>
            <TableHeader onChangeEntries={handleEntries} />
            <Table
              tableDate={usersData}
              //   searchItem={searchTerm}
              entries={entries}
              keysToFilter={tableData}
              tableHeaderRow={tableHeaderRow}
              totalDataCount={totalDataCount}
              pageNumber={pageNumber}
              onPageChange={handlePageChange}
            />
          </div>
        </main>
      ) : (
        <AnalyticsheaderUser/>
      )}
    </>
  );
};
