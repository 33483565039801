import { AnalyticsTemplate } from "../../design-systems";
import { AnalyticsSideBar } from "../../design-systems/Molecules/sidebars";
import { RootState } from "../../store";
import { sideBarMenu } from "../../utils";
import { useSelector } from "react-redux";
export const Analytics = () => {
  const sideBarMenu = useSelector((state: RootState)=>state.analyticsSidebar.value)
  return(
     <div className="flex h-screen">
      <AnalyticsSideBar sideBarMenu={sideBarMenu}/>
      <AnalyticsTemplate />
    </div>
  )
  }


