import React, { useState } from 'react';
import { Button, Typography, Spinner } from '../../Atoms';
import { inputs } from './utils';
import { FieldValues, useForm } from 'react-hook-form';
import { useAuthentication, useToast } from '../../../hooks';
import { saveAccessToken, saveUserName } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../../store/reducers/';
import { getSpinnerSize, getSpinnerStokeColor } from '../../Atoms/Button/utils';

export const Login = () => {
  const [isTrue, setIsTrue] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { successToast, warningToast } = useToast();
  const { loginAsync } = useAuthentication();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (values: FieldValues) => {
    try {
      setIsTrue(true);
      const data = await loginAsync?.(values);
      const { token } = data.data;
      saveAccessToken(data.data.token);
      saveUserName(data.data.user.name);

      // await dispatch(login({ token}));
      setIsTrue(false);

      successToast('successfully logged in');
      return navigate('/users');
    } catch (error) {
      warningToast('Login failed');
      setIsTrue(false);
    }
  });

  const spinnerClasses = [getSpinnerStokeColor('neon'), getSpinnerSize('large')].join(' ');

  return (
    <div className='h-screen flex justify-center items-center bg-[#F4F7FC]'>
      <div>
        <Typography className='text-center mb-2' size='subtitle'>
          Welcome
        </Typography>
        <Typography size='caption' className='text-center mb-2 font-normal text-lg'>
          Sign in to your account to continue
        </Typography>
        <form onSubmit={onSubmit} className='flex gap-4 flex-col items-center justify-between bg-white p-20'>
          {inputs.map(({ InputField, name, rules, ...rest }) => (
            <InputField
              control={control}
              error={errors[name]?.message?.toString()}
              innerClassName='!bg-transparent  font-normal p-3 text-lg w-full md:w-[300px] lg:w-[350px] h-8 lg:h-12 '
              labelClassName='!text-caption !leading-caption !tracking-caption !font-normal  !font-normal !text-lg'
              key={name}
              name={name}
              rules={rules}
              {...rest}
            />
          ))}
          {isTrue ? (
            <Spinner className={spinnerClasses} />
          ) : (
            <Button
              color='primary'
              size='medium'
              variant='solid'
              type='submit'
              className='w-full !px-16 uppercase md:w-auto'
            >
              LOGIN
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};
