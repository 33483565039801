import CoreAPIService from "./CoreAPIService";
import { API_ENDPOINTS } from "../utils";

class ReactionsService {
  getReactions = async (pageSize: number, pageNumber: number) =>
    CoreAPIService.get<ReactionResponse>(
      `${API_ENDPOINTS.PRIVATE.REACTIONS}/${pageSize}/${pageNumber}`
    );
  addReactions = async (payload: FormData) =>
    CoreAPIService.post<ReactionResponse>(
      `${API_ENDPOINTS.PRIVATE.REACTIONS}`,
      payload
    );
  deleteReactions = async (reactionId: number) =>
    CoreAPIService.delete<ReactionResponse>(
      `${API_ENDPOINTS.PRIVATE.REACTIONS}/${reactionId}`
    );
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ReactionsService();
