import { AnalyticsService } from "../../api-services";

export const useAnalytics = () => {
  const getAnalyticsAsync = (pageNumber:any,pageSize:any,data?: any) => AnalyticsService.getAnalytics(pageNumber,pageSize,data);
  // const getAnalyticsFilterAsync = (data?:any) => AnalyticsService.getAnalyticsFilter()
  const getAnalyticsUserAsync = (
    pageNumber: number,
    pageSize: number,
    data?: any
  ) => AnalyticsService.getAnalyticsUser(pageNumber, pageSize);
  const getAnalyticsUserFilterAsync = (
    pageNumber: number,
    pageSize: number,
    data?: any
  ) => AnalyticsService.getAnalyticsUserFIlter(pageNumber, pageSize, data);
  return {
    getAnalyticsAsync,
    getAnalyticsUserAsync,
    getAnalyticsUserFilterAsync
    // getAnalyticsFilterAsync,
  };
};
