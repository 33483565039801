export const tableData = [
  "name",
  "group",
  "description",
  "version",
  "createdAt",
  "isActive",
  "id",
  'delete'

];
export const tableHeaderRow = [
  "Name",
  "Group",
  "Description",
  "Version",
  "Created At",
  "Action",
  'Delete'
];
