import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './rootreducer';

// import sideBarReducer from "./reducers/sidebarSlice"
// import authReducer from "./reducers/authSlice"

// impot {rootReducer}


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Add any other reducers you want to persist
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer
})
// export const persisttore = configureStore({
//   reducer: persistedReducer,
// });
export const persist = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch