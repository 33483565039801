import { useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { tableData, obj, tableHeaderRow } from './utils';
import { settingOptions } from '../../../utils';
import { useEnvironments } from '../../../hooks';
import { FilteredTableData, changeKeyNameOfObject, convertTimeZone } from '../../../utils/helper';
import { Button, RefetchIcon, useModal } from '../../Atoms';
import { AddEnvironmentModal, TableHeader } from '../../Molecules';
import { ProceedModal } from '../../Molecules/Models/ProceedModal';
export const EnvironmentsHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState(10);
  const [isLoading, setLoading] = useState(false);

  const [usersData, setUsersData] = useState<any>([]);
  const [rowData, setRowData] = useState<{ id: number; value: boolean }>({
    id: 0,
    value: false,
  });
  const { environmentsAsync, environmentActivesAsync, environmentAddAsync } = useEnvironments();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, entries: number) => {
    try {
      return await environmentsAsync(data, entries);
    } catch (error) {}
  };
  const handleActivation = async (id: number, value: boolean) => {
    try {
      const data = await environmentActivesAsync(id, !value);
      if (data) {
        await handlePageChange(pageNumber);
        proceedModalClose();
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  const handleDataEnvironment = async (data: FormData) => {
    try {
      await environmentAddAsync?.(data);
      handlePageChange(pageNumber);
      close();
    } catch (error) {}
  };

  const {
    Modal: AddModal,
    open,
    close,
  } = useModal(<AddEnvironmentModal save={handleDataEnvironment} />, 'Add Environment');
  const handlePageChange = async (pageNumber: number) => {
    try {
      setLoading(true);
      const data = await getUserData(pageNumber, entries);
      if (data) {
        setLoading(false);
        setTotalDataCount(data.count);
        setPageNumber(pageNumber);
        const filteredData = await FilteredTableData(changeKeyNameOfObject(data.data, obj), tableData);
        filteredData.forEach((item) => {
          const value = item.Action;
          item.createdAt = convertTimeZone(item.createdAt);
          item.Action = (
            <div className='flex justify-center'>
              <Button
                onClick={() => modalData(item.id, value)}
                className={`!px-2 !py-1 text-sm c text-white ${!value ? '!bg-red-600' : '!bg-[#28a745]'}`}
              >
                {!item.Action ? 'Make Active' : 'Make Inactive'}
              </Button>
            </div>
          );
        });
        setUsersData(filteredData);
      }
    } catch (error) {}
  };
  useMemo(async () => {
    handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries, pageNumber, searchTerm]);

  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };

  const handleProceed = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalClose();
    }
    handleActivation(rowData.id, rowData.value);
  };

  const modalData = (id: number, value: boolean) => {
    proceedModalOpen();
    setRowData((prev) => ({
      ...prev,
      id,
      value,
    }));
  };

  const {
    Modal: AddProceedModal,
    open: proceedModalOpen,
    close: proceedModalClose,
  } = useModal(<ProceedModal proceed={handleProceed} />, '');

  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900 p-6'>
      <Navbar options={settingOptions} />
      <div className='bg-white p-4 shadow-md '>
        <div className='justify-between flex'>
          {/* <div>
            <p className="text-[16px] mb-3">environments</p>
          </div> */}
          <div className='flex justify-between gap-2' style={{ marginLeft: 'auto' }}>
            <button onClick={() => handlePageChange(pageNumber)}>
              <RefetchIcon />
            </button>
            <Button onClick={open} color='primary' size='small' className='w-full !px-10 !bg-[#3B7DDD] mb-2'>
              Add Environment
            </Button>
          </div>
        </div>
        <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />
        <Table
          tableDate={usersData}
          searchItem={searchTerm}
          totalDataCount={totalDataCount}
          entries={entries}
          keysToFilter={tableData}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          tableHeaderRow={tableHeaderRow}
          isLoading={isLoading}
        />
        <AddModal />
        <AddProceedModal />
      </div>
    </main>
  );
};
