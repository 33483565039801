import { FeaturesHeader,Sidebar } from "../..";
import { sideBarMenu } from "../../../utils";

export const FeaturesTemplate = () => {
return(
   <div className="flex h-screen">
    <Sidebar sideBarMenu={sideBarMenu} />
    <FeaturesHeader />
  </div>
)
};
