import CoreAPIService from './CoreAPIService'
import { API_ENDPOINTS } from '../utils'

class BackgroundFilesService {
    getBackgroundFiles = async (pageNumber: number,pageSize:number) => CoreAPIService.get<BackgroundFilesResponse>(`${API_ENDPOINTS.PRIVATE.BACKGROUNDFILES}/${pageNumber}/${pageSize}`)
    addBackgroundFiles = async (payload:  FormData) => CoreAPIService.post<BackgroundFilesResponse>(`${API_ENDPOINTS.PRIVATE.BACKGROUNDFILES}`,payload)
    deleteBackgroundFiles = async (fileId: number) =>
    CoreAPIService.delete<BackgroundFilesResponse>(
      `${API_ENDPOINTS.PRIVATE.BACKGROUNDFILES}/${fileId}`
    );
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new BackgroundFilesService()
