export const tableData = [
  "name",
  "userLimit",
  "description",
  "version",
  "createdAt",
  "type",
  "creator",
  "Action",
  "id",
  "delete"
];
export const obj = {
  isActive: "Action",
};
export const tableHeaderRow = [
  "Name",
  "User Limit",
  "Description",
  "Version",
  "CreatedAt",
  "Type",
  "Creator",
  "Action",
  "Delete"
];
