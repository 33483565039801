import {ItemsService} from "../../api-services"

export const useItems = () => {
    const itemAsync = (pageNumber: number,pageSize:number) => ItemsService.getItems(pageNumber,pageSize)
    const itemActiveAsync = (id: number, value:boolean) => ItemsService.items(id,value)
    const itemAddAsync = (data:FormData) => ItemsService.addItems(data)
    const editItemAsync = (data:any,id:any) => ItemsService.EditItem(data,id)

    const itemDeleteAsync = (itemId:number) => ItemsService.deleteItems(itemId)

    
    
    return {
        itemAsync,
        itemActiveAsync,
        itemAddAsync,
        itemDeleteAsync,
        editItemAsync
      } 
}