import { ReactionsService } from "../../api-services";

export const useReactions = () => {
  const reactionsAsync = (pageSize: number, pageNumber: number) =>
    ReactionsService.getReactions(pageSize, pageNumber);
  const addReactionsAsync = (data: FormData) =>
    ReactionsService.addReactions(data);
  const reactionsDeleteAsync = (reactionId: number) =>
    ReactionsService.deleteReactions(reactionId);

  return {
    reactionsAsync,
    addReactionsAsync,
    reactionsDeleteAsync,
  };
};
