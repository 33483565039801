import { Button } from "../../../Atoms";
import { ProceedModalProps } from "./interface";

export const ProceedModal = ({ proceed }: ProceedModalProps) => {
  return (
    <>
        <h1 className="text-center">Are you sure want to proceed?</h1>
        <div className="flex w-full px-4 py-4">
          <div className="mb-6 w-full px-4 py-4">
            <Button
              color="primary"
              size="small"
              variant="solid"
              type="submit"
              className="w-full !px-10"
              onClick={() => proceed?.(false)}
            >
              No
            </Button>
          </div>

          <div className="mb-6 w-full px-4 py-4">
            <Button
              color="primary"
              size="small"
              variant="solid"
              type="submit"
              className="w-full !px-10"
              onClick={() => proceed?.(true)}
            >
              Yes
            </Button>
          </div>
        </div>
    </>
  );
};
