import CoreAPIService from './CoreAPIService'
import { API_ENDPOINTS, checkAccessToken } from '../utils'
import { FieldValues } from "react-hook-form";
import axios from 'axios';

const BASE_API_ENDPOINT =
  process.env.REACT_APP_PUBLIC_NETWORK_TYPE === 'testnet'
    ? process.env.REACT_APP_PUBLIC_API_TESTNET_BACKEND_URL
    : process.env.REACT_APP_PUBLIC_API_MAINNET_BACKEND_URL;
class SeriesService {
    getCategory = async (pageSize: number, pageNumber: number) => {
        const accessToken = checkAccessToken();
        let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.GETCATEGORY}`;
        try {
          return axios.get(url, { headers: { authorization: accessToken } }).then((response:any) => {
            return response.data;
          });
        } catch (error) {}
      };
    // getCategory = async (pageSize: number,pageNumber:number) => CoreAPIService.get<BackgroundFilesResponse>(`${API_ENDPOINTS.PRIVATE.SERIES}/${pageSize}/${pageNumber}`)
    addCategory  = async (payload:  FieldValues) => CoreAPIService.post<BackgroundFilesResponse>(`${API_ENDPOINTS.PRIVATE.ADDCATEGORY}`,payload)
    deleteCategory  = async (id:number) => CoreAPIService.post<BackgroundFilesResponse>(`${API_ENDPOINTS.PRIVATE.SERIES_DELETE}/${id}`,)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new SeriesService()
