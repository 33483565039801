import CoreAPIService from './CoreAPIService'
import { API_ENDPOINTS } from '../utils'

class AnimationsService {
    getAnimations = async (pageNumber: number,pageSize:number)  => CoreAPIService.get<AnimationsResponse>(`${API_ENDPOINTS.PRIVATE.ANIMATIONS}/${pageNumber}/${pageSize}`)
    UpdateAnimation = async (id:number, isActive:Boolean) => CoreAPIService.post<AnimationsResponse>(`${API_ENDPOINTS.PRIVATE.ANIMATIONS}/${id}/${isActive}`)
    DeleteAnimation = async (animationId:number) => CoreAPIService.delete<AnimationsResponse>(`${API_ENDPOINTS.PRIVATE.ANIMATIONS}/${animationId}`)
    addAnimations = async (payload:  FormData) => CoreAPIService.post<AnimationsResponse>(`${API_ENDPOINTS.PRIVATE.ANIMATIONS}`,payload)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new AnimationsService()
