import { useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { tableData, tableHeaderRow } from './utils';
import { settingOptions } from '../../../utils';
import { useAnimations } from '../../../hooks';
import { FilteredTableData, convertTimeZone } from '../../../utils/helper';
import { Button, RefetchIcon, useModal } from '../../Atoms';
import { AddAnimationsModal, TableHeader } from '../../Molecules';
import { Spinner } from 'flowbite-react';
import { ProceedModal } from '../../Molecules/Models/ProceedModal';
import { DeleteModal } from '../../Molecules/Models/deleteModal';

export const AnimationsHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState(10);
  const [usersData, setUsersData] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);

  const { animationsAsync, addAnimationsAsync, addAnimationsActiveAsync, addAnimationsDeleteAsync } = useAnimations();
  const [pageNumber, setPageNumber] = useState(1);
  const [isTrue, setIsTrue] = useState<boolean>(false);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [rowData, setRowData] = useState<{ id: number; value: boolean }>({
    id: 0,
    value: false,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, entries: number) => {
    try {
      return await animationsAsync(data, entries);
    } catch (error) {}
  };

  const handlePageChange = async (pageNumber: number) => {
    setLoading(true);
    try {
      setIsTrue(false);
      const data = await getUserData(pageNumber, entries);
      if (data) {
        setLoading(false);

        setTotalDataCount(data.count);
        setPageNumber(pageNumber);
        const filteredData = await FilteredTableData(data.data, tableData);
        filteredData.forEach((item) => {
          const value = item.isActive;
          item.createdAt = convertTimeZone(item.createdAt);
          item.isActive = (
            <div className='flex justify-center '>
              <Button
                onClick={() => modalData(item.id, value)}
                className={Boolean(!value) ? '!bg-red-600 !text-white' : '!bg-[#28a745] !text-white'}
              >
                {!item.isActive ? 'Make Active' : 'Make Inactive'}
              </Button>
            </div>
          );
          item.delete = (
            <div className='flex justify-center'>
              <Button onClick={() => deleteData(item.id)} className='bg-blue-600'>
                Delete
              </Button>
            </div>
          );
        });
        setUsersData(filteredData);
        setIsTrue(true);
      }
    } catch (error) {}
  };
  useMemo(async () => {
    handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, entries, searchTerm]);
  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };
  const handleDataAnimations = async (data: FormData) => {
    try {
      await addAnimationsAsync?.(data);
      handlePageChange(pageNumber);
      close();
    } catch (error) {}
  };
  const {
    Modal: AddModal,
    open,
    close,
  } = useModal(<AddAnimationsModal save={handleDataAnimations} />, 'Add Animation');
  const handleActivation = async (id: number, value: boolean) => {
    try {
      const data = await addAnimationsActiveAsync(id, !value);
      if (data) {
        await handlePageChange(pageNumber);
        proceedModalClose();
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  const handlDelete = async (id: number) => {
    try {
      const data = await addAnimationsDeleteAsync(id);
      if (data) {
        await handlePageChange(pageNumber);
        proceedModalCloseDelete();
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  const handleProceed = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalClose();
    }
    handleActivation(rowData.id, rowData.value);
  };
  const handleProceedDelete = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalCloseDelete();
    }
    handlDelete(rowData.id);
  };
  const modalData = (id: number, value: boolean) => {
    proceedModalOpen();
    setRowData((prev) => ({
      ...prev,
      id,
      value,
    }));
  };
  const deleteData = (id: number) => {
    proceedModalOpenDelete();
    setRowData((prev) => ({
      ...prev,
      id,
    }));
  };

  const {
    Modal: AddProceedModal,
    open: proceedModalOpen,
    close: proceedModalClose,
  } = useModal(<ProceedModal proceed={handleProceed} />, '');

  const {
    Modal: AddProceedDeleteModal,
    open: proceedModalOpenDelete,
    close: proceedModalCloseDelete,
  } = useModal(<DeleteModal proceed={handleProceedDelete} />, '');

  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900 p-6'>
      <Navbar options={settingOptions} />
      <div className='bg-white p-4 shadow-md '>
        <div className='justify-between flex'>
          {/* <div>
            <p className="text-[16px] mb-3">animations</p>
          </div> */}
          <div className='flex justify-between gap-2' style={{ marginLeft: 'auto' }}>
            <button onClick={() => handlePageChange(pageNumber)}>{!isTrue ? <Spinner /> : <RefetchIcon />}</button>
            <Button onClick={open} color='primary' size='small' className='w-full !px-10 !bg-[#3B7DDD] mb-2'>
              Add animations
            </Button>
          </div>
        </div>
        <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />
        <Table
          tableDate={usersData}
          searchItem={searchTerm}
          entries={entries}
          keysToFilter={tableData}
          tableHeaderRow={tableHeaderRow}
          totalDataCount={totalDataCount}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          isLoading={isLoading}
        />
        <AddModal />
        <AddProceedModal />

        <AddProceedDeleteModal />
      </div>
    </main>
  );
};
