import {SeriesService} from "../../api-services"
import { FieldValues } from "react-hook-form";

export const useSeries = () => {
    const seriesAsync = (pageSize: number,pageNumber:number) => SeriesService.getSeries(pageSize,pageNumber)
    const seriesAddAsync = (data: FieldValues) => SeriesService.addSeries(data)
    const seriesDeleteAsync = (id: number) => SeriesService.deleteSeries(id)
    
    return {
        seriesAsync,
        seriesAddAsync,
        seriesDeleteAsync
      } 
}