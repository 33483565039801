import CoreAPIService from './CoreAPIService'
import { API_ENDPOINTS } from '../utils'
import { FieldValues } from "react-hook-form";

class SeriesService {
    getSeries = async (pageSize: number,pageNumber:number) => CoreAPIService.get<BackgroundFilesResponse>(`${API_ENDPOINTS.PRIVATE.SERIES}/${pageSize}/${pageNumber}`)
    addSeries = async (payload:  FieldValues) => CoreAPIService.post<BackgroundFilesResponse>(`${API_ENDPOINTS.PRIVATE.SERIES_ADD}`,payload)
    deleteSeries = async (id:number) => CoreAPIService.post<BackgroundFilesResponse>(`${API_ENDPOINTS.PRIVATE.SERIES_DELETE}/${id}`,)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new SeriesService()
