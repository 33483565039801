import { AnimationsHeader,Sidebar } from "../..";
import { sideBarMenu } from "../../../utils";

export const AnimationsTemplate = () => {
return(
   <div className="flex h-screen">
    <Sidebar sideBarMenu={sideBarMenu} />
    <AnimationsHeader />
  </div>
)
};
