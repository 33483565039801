import CoreAPIService from './CoreAPIService'
import { API_ENDPOINTS, checkAccessToken } from '../utils'
import axios from 'axios';
const BASE_API_ENDPOINT = process.env.REACT_APP_PUBLIC_NETWORK_TYPE === "testnet" ?  process.env.REACT_APP_PUBLIC_API_TESTNET_BACKEND_URL : process.env.REACT_APP_PUBLIC_API_MAINNET_BACKEND_URL;

class AllWorldsService {
    // getAllWorlds = async (pageSize:  number,pageNumber:number) => {
    //     const accessToken = checkAccessToken();
    //       let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.ALLWORLDS}/${pageSize}/${pageNumber}`
    //     try {
    //       return axios
    //         .get(url, { headers: { authorization: accessToken } })
    //         .then((response) => {
    //           return response.data;
    //         });
    //     } catch (error) {}
    //   };
      getAllWorlds = async (pageSize:  number,pageNumber:number) => CoreAPIService.get<allWorldsResponse>(`${API_ENDPOINTS.PRIVATE.ALLWORLDS}/${pageSize}/${pageNumber}`)
    updateAllWorlds = async (payload:FormData) => CoreAPIService.post<allWorldsResponse>(`${API_ENDPOINTS.PRIVATE.UPDATEWORLDS}`,payload)
    updateStatusWorlds = async (id:number) => CoreAPIService.post<allWorldsResponse>(`${API_ENDPOINTS.PRIVATE.UPDATESTATUSWORLDS}/${id}`)
    FeaturedWorlds = async (id: number, data: any) => CoreAPIService.put<allWorldsResponse>(`${API_ENDPOINTS.PRIVATE.FEATUREDWORLDS}/${id}`, data)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new AllWorldsService()
