import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { SelectProps } from './interface';
import { Typography } from '../Typography';
import { ArrowDownIcon } from '../icons';

export const Select: React.FC<SelectProps> = ({
  className,
  value,
  options = [],
  setSelectedCollectionAsset,
  label,
  required = false,
  labelTextClassName,
  slectedClassName,
}) => {
  return (
    <div className={className}>
      {label && (
        <Typography variant='condensed' size='caption' className={labelTextClassName}>
          {label}
          {required && <span className='font-bold text-[#FF0000]'>*</span>}
        </Typography>
      )}
      <Listbox value={value} onChange={setSelectedCollectionAsset}>
        <div className='relative mt-1'>
          <Listbox.Button
            className={`${slectedClassName} relative flex w-full cursor-default items-center border border-neutral-600 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:ring-offset-2 sm:text-sm`}
          >
            {value ? (
              <span className='block truncate'>{value?.label}</span>
            ) : (
              <Typography size='body' variant='condensed' className='text-neutral-600'>
                Select
              </Typography>
            )}
            <span className='options-center pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <ArrowDownIcon className='h-4 w-5 stroke-neutral-600' aria-hidden='true' />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options className=' absolute z-10 mt-1 max-h-60 w-full overflow-auto border-none bg-neutral-700 py-1 text-base shadow-lg focus:outline-none sm:text-sm'>
              {options?.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-neutral-300 text-neutral-800' : 'text-neutral-100'
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {item?.label}
                      </span>
                      {selected ? (
                        <span className='options-center absolute inset-y-0 left-0 flex pl-3 text-neutral-100'>
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
