import { Fragment } from 'react';
import type { ModalProps } from './interface';
import { Dialog, Transition } from '@headlessui/react';
import { Typography } from '../Typography';
import { CloseIcon } from '../icons';

export const Modal: React.FC<ModalProps> = ({
  children,
  open = false,
  onClose,
  heading = 'Heading',
  className = '',
  jsxElement,
}) => {
  const handleClose = () => {
    onClose?.();
  };
  const modalClassName = ['bg-black/30 fixed inset-0 bg-transparent bg-[#000000a6] z-[2999]', className].join(' ');
  return (
    <Transition
      show={open}
      enter='transition duration-100 ease-out'
      enterFrom='transform scale-95 opacity-0'
      enterTo='transform scale-100 opacity-100'
      leave='transition duration-75 ease-out'
      leaveFrom='transform scale-100 opacity-100'
      leaveTo='transform scale-95 opacity-0'
      as={Fragment}
    >
      <Dialog onClose={() => {}} className='bg-brand-500'>
        <div
          className=' bg-black/50  border border-gray-300 shadow-xl backdrop-blur-3xl fixed inset-0 z-[2999] !shadow-2xl'
          aria-hidden='true'
        />
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className={modalClassName} aria-hidden='true' />

        {/* Full-screen container to center the panel */}
        <div className='fixed inset-0 z-[3000] flex items-center justify-center'>
          <Dialog.Panel>
            <div className='h-screen w-screen uppercase sm:h-full sm:w-full sm:rounded-3xl bg-neutral-800'>
              <div className='flex flex-row justify-between p-4'>
                {typeof heading === 'string' ? (
                  <Typography variant='condensed' size='subtitle'>
                    {heading}
                  </Typography>
                ) : (
                  <div>{heading}</div>
                )}
                {!heading && jsxElement && jsxElement}

                <button type='button' onClick={handleClose}>
                  <CloseIcon className='fill-neutral-500' />
                </button>
              </div>
              {children}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
};
