import { useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { tableData, tableHeaderRow } from './utils';
import { useAllEvents } from '../../../hooks';
import { FilteredTableData, settingOptions } from '../../../utils';
import { TableHeader } from '../../Molecules';
import { ProceedModal } from '../../Molecules/Models/ProceedModal';
import { Button, useModal } from '../../Atoms';
import { RedOrderModal } from '../../Molecules/Models/reOrderModel';

export const AllEventsHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState(10);
  const [usersData, setUsersData] = useState<any>([]);
  const { allEventsAsync, allUpdateEventsAsync, allUpdateEventsOrderAsync } = useAllEvents();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [rowData, setRowData] = useState<{ id: number; value: number | null; orderNo: number | null }>({
    id: 0,
    value: null,
    orderNo: null,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, entries: number) => {
    return await allEventsAsync(data, entries);
  };
  const handleApprovalStatus = async (value: number, id: number) => {
    try {
      const obj = { id, approvalStatus: value };
      const data = await allUpdateEventsAsync(obj);
      if (data) {
        await handlePageChange(pageNumber);
        proceedModalClose();
      }
    } catch (error) {
      console.log('err', error);
    }
  };
  const handlePageChange = async (pageNumber: number) => {
    try {
      setLoading(true);
      const data = await getUserData(pageNumber, entries);
      if (data) {
        setLoading(false);
        setTotalDataCount(data.data.count);
        setPageNumber(pageNumber);
        const filteredData = await FilteredTableData(data.data.rows, tableData);
        filteredData.forEach((item) => {
          // eslint-disable-next-line jsx-a11y/alt-text
          const orderNo = item.orderNo;
          item.status = (
            <select
              onChange={(e) => modalData(Number(e.target.value), item.id)}
              className='form-control changeOp'
              defaultValue={item.approvalStatus}
            >
              <option value='1'>Approved</option>
              <option value='0'>Pending</option>
              <option value='-1'>Rejected</option>
            </select>
          );
          item.sequence = (
            <input
              onBlur={(e) => modalOrderData(Number(e.target.value), orderNo, item.id)}
              type='number'
              defaultValue={item.sequence}
              className='w-24 h-8 text-sm px-2 py-1 border rounded'
              min={0}
              onKeyDown={(e) => {
                if (e.key === '-' || e.key === '.') {
                  e.preventDefault();
                }
              }}
            />
          );
        });
        setUsersData(filteredData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useMemo(async () => {
    handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, entries]);
  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };

  const handleProceed = async (isConfirmed: boolean) => {
    if (!isConfirmed) {
      setUsersData([]);
      await handlePageChange(pageNumber);
      return proceedModalClose();
    }

    if (rowData?.id !== null && rowData?.value !== null) {
      handleApprovalStatus(rowData.id, rowData.value);
    }
  };

  const modalData = (id: number, value: number) => {
    proceedModalOpen();
    setRowData((prev) => ({
      ...prev,
      id,
      value,
    }));
  };
  const handelUpdateIndex = async (newIndex: any, oldIndex: number, id: number) => {
    try {
      if (newIndex <= 0 || Number(newIndex) === oldIndex) {
        alert('Index should be greater than 0 and different from the old index.');
        return;
      }

      const smallIndex = Math.min(oldIndex, Number(newIndex));
      const greaterIndex = Math.max(oldIndex, Number(newIndex));
      const data = await getUserData(pageNumber, entries);
      proceedRedorderModalClose();
      const updatedData = data?.data.rows.filter(
        (item: any) => item.orderNo >= smallIndex && item.orderNo <= greaterIndex
      );

      if (oldIndex > Number(newIndex)) {
        updatedData.reverse();
      }
      await allUpdateEventsOrderAsync?.({
        // body: updatedData,
        eventId: id,
        newOrderNo: newIndex,
      });
    } catch (error) {
      console.error('Error occurred:', error);
      // Handle error
    } finally {
      setUsersData([]);
      handlePageChange(pageNumber);
    }
  };
  const handleRedOrderProceed = async (isConfirmed: boolean) => {
    if (!isConfirmed) {
      setUsersData([]);
      await handlePageChange(pageNumber);
      return proceedRedorderModalClose();
    }
    if (rowData?.id !== null && rowData?.value !== null && rowData.orderNo) {
      handelUpdateIndex(rowData.value, rowData.id, rowData.orderNo);
    }
  };
  const modalOrderData = (value: any, id: number, orderNo: number) => {
    proceedReorderModalOpen();
    setRowData((prev) => ({
      ...prev,
      value,
      id,
      orderNo,
    }));
  };
  const {
    Modal: AddProceedModal,
    open: proceedModalOpen,
    close: proceedModalClose,
  } = useModal(<ProceedModal proceed={handleProceed} />, '');

  const {
    Modal: ReOrderModal,
    open: proceedReorderModalOpen,
    close: proceedRedorderModalClose,
  } = useModal(<RedOrderModal proceed={handleRedOrderProceed} />, '');
  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900  p-6'>
      <Navbar options={settingOptions} />
      <div className='bg-white p-4 shadow-md '>
        <div className='justify-between flex'>
          {/* <div>
            <p className="text-[16px] mb-3">All Events</p>
          </div> */}
        </div>
        <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />
        <Table
          tableDate={usersData}
          searchItem={searchTerm}
          totalDataCount={totalDataCount}
          entries={entries}
          keysToFilter={tableData}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          tableHeaderRow={tableHeaderRow}
          isLoading={isLoading}
        />

        <AddProceedModal />
        <ReOrderModal />
      </div>
    </main>
  );
};
