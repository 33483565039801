import CoreAPIService from './CoreAPIService'
import { API_ENDPOINTS } from '../utils'

class MuseumsService {
    getMuseums = async (pageNumber:  number,pageSize:number) => CoreAPIService.get<MuseumsResponse>(`${API_ENDPOINTS.PRIVATE.MUSEUMS}/${pageNumber}/${pageSize}`)
    getActiveMuseums = async (id:number, value:boolean) => CoreAPIService.post<MuseumsResponse>(`${API_ENDPOINTS.PRIVATE.MUSEUMS}/${id}/${value}`)
    addMuseums = async (payload:FormData) => CoreAPIService.post<MuseumsResponse>(`${API_ENDPOINTS.PRIVATE.MUSEUMS}`,payload)
    deleteMuseums = async (museumId:number) => CoreAPIService.delete<MuseumsResponse>(`${API_ENDPOINTS.PRIVATE.MUSEUMS}/${museumId}`)

}
// eslint-disable-next-line import/no-anonymous-default-export
export default new MuseumsService()
