import { createSlice } from "@reduxjs/toolkit";

export interface CounterStateArr {
  value: any[];
  heading: string;
}

const initialState: CounterStateArr = {
  value: [],
  heading: "World",
};

export const analyticsSideBarSlice = createSlice({
  name: "analyticsSidebar",
  initialState,
  reducers: {
    analyticsFilterHistory: (state, data) => {
      state.value = [...state.value, data];
    },
    analyticsFilterValue: (state, action) => {
      state.heading = action?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { analyticsFilterHistory, analyticsFilterValue } =
  analyticsSideBarSlice.actions;

export default analyticsSideBarSlice.reducer;
