import { DashboardHeader,Sidebar } from "../../../design-systems";
import { sideBarMenu } from "../../../utils";


export const DashboardPageTemplate = () => {
return(
  <div className="flex bg-[#F6F7FB] h-[100%]">
    <Sidebar sideBarMenu={sideBarMenu} />
    <DashboardHeader />
  </div>
)
};
