import { Controller } from 'react-hook-form';
import { RawFormInput } from './RawInput';
import type { FormInputProps } from './interface';

export const FormInput: React.FC<FormInputProps> = ({
  name = '',
  variant = 'primary',
  type = 'text',
  rules,
  control,
  error,
  min = '',
  required,
  labelClassName = '',
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, value, ...rest } }) => (
        <RawFormInput
          variant={variant}
          labelClassName={labelClassName}
          required={required}
          error={error}
          type={type}
          min={min}
          inputRef={ref}
          {...props}
          {...rest}
        />
      )}
    />
  );
};
