import { Login } from "../../design-systems";
import { getAuth } from "../../utils";
import { Navigate } from "react-router-dom";
export const Auth = () => {
  if (getAuth()) {
    return <Navigate to="/users" replace={true} />;
  }

  return <Login />;
};
