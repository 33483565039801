
export const LOCAL_ACCESS_TOKEN_KEY = 'access-token'

export const checkAccessToken = () => localStorage.getItem(LOCAL_ACCESS_TOKEN_KEY)
export const disableAccessToken = () => localStorage.removeItem(LOCAL_ACCESS_TOKEN_KEY)
export const saveAccessToken = (token: string) => localStorage.setItem(LOCAL_ACCESS_TOKEN_KEY, token)
export const saveUserName = (userName: any) => localStorage.setItem('user_name', JSON?.stringify(userName))

export const getUserName = () =>  JSON?.parse(localStorage.getItem("user_name") ?? "null");
export const removeUserName = () => localStorage.removeItem("user_name")

