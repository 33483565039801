import {BackgroundFilesService} from "../../api-services"

export const useBackgroundFiles = () => {
    const backgroundFilesAsync = (pageNumber: number,pageSize:number) => BackgroundFilesService.getBackgroundFiles(pageNumber,pageSize)
    const addBackgroundFilesAsync = (data: FormData) => BackgroundFilesService.addBackgroundFiles(data)
    const deleteBackgrounfFileAsync = (fileId: number)=> BackgroundFilesService.deleteBackgroundFiles(fileId)
    
    return {
        backgroundFilesAsync,
        addBackgroundFilesAsync,
        deleteBackgrounfFileAsync
      } 
}