import { AnimationsService } from "../../api-services";

export const useAnimations = () => {
  const animationsAsync = (pageNumber: number,pageSize:number) =>
    AnimationsService.getAnimations(pageNumber,pageSize);
  const addAnimationsAsync = (data: FormData) =>
    AnimationsService.addAnimations(data);
    const addAnimationsActiveAsync = (id: number, value:boolean) => AnimationsService.UpdateAnimation(id,value)
const addAnimationsDeleteAsync =(animationId: number) => AnimationsService.DeleteAnimation(animationId)
  return {
    animationsAsync,
    addAnimationsAsync,
    addAnimationsActiveAsync,
    addAnimationsDeleteAsync
  };
};
