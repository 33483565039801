import {  Sidebar } from '../..';
import { sideBarMenu } from '../../../utils';
import { LobbyWorldsHeader } from '../../Organisms/LobbyWorldsHeader ';

export const LobbyWorldsTemplate = () => {
  return (
    <div className='flex h-screen'>
      <Sidebar sideBarMenu={sideBarMenu} />
      <LobbyWorldsHeader />
    </div>
  );
};
