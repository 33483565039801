import { Navbar } from "../../Organisms";
import { FilteredTableData, OPTIONS } from "../../../utils";
import { Table } from "../../Organisms";
import { CSVIcon, FilterIcon, Typography, useModal } from "../../Atoms";
import { FilterWordModal } from "../../Molecules/Models";
import { useAnalytics } from "../../../hooks";
import { useMemo, useState } from "react";
import { tableData, tableHeaderRow,tableDataUser,tableHeaderRowUser,tableDataFilterUser } from "./utils";
import { TableHeader } from "../../Molecules";
import { data } from "../../Templates/AnalyticsTemplate/utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { analyticsFilterHistory } from "../../../store/reducers/";

import { log } from "console";
export const AnalyticsheaderUser = () => {
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);
  const [worldCount, setWorldCount] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [usersData, setUsersData] = useState<any>([]);
  const [entries, setEntries] = useState(10);
  const [check,setCheck]=useState(false)
  const { getAnalyticsUserAsync,getAnalyticsUserFilterAsync } = useAnalytics();
  const {
    Modal: FilterWordonModal,
    open,
    close,
  } = useModal(
    <FilterWordModal save={handleFilterDataItem} />,
    "Filter By User"
  );
  const handlePageChange = async (pageNumber:any,filter?: any) => {
    try {
      dispatch(analyticsFilterHistory(filter?.world));
      const data = await getAnalyticsUserAsync(pageNumber,entries,filter);
      close();
      if (data) {
        setTotalDataCount(data?.total_users);
        setPageNumber(pageNumber);
        const filteredData = await FilteredTableData(data.rows, tableDataUser);

        setWorldCount(filteredData.length);

        setUsersData(filteredData);
      }
    } catch (error) {
      close();
      console.log(error);
    }
  };
  useMemo(async () => {
    handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries,pageNumber]);
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1)

  };
const handleFilter=async(filter?:any)=>{
  try {
    dispatch(analyticsFilterHistory(filter?.world));
    const data = await getAnalyticsUserFilterAsync(pageNumber,entries);
    close();
    if (data) {
      setTotalDataCount(data?.total_users);
      setPageNumber(pageNumber);
      const filteredData = await FilteredTableData(data.data, tableDataFilterUser);
      setCheck(true)
      setWorldCount(filteredData.length);

      setUsersData(filteredData);
    }
  } catch (error) {
    setCheck(false)
    close();
    console.log(error);
  }
}
  async function handleFilterDataItem(data: any) {
    try {
      handleFilter(data);
    } catch (error) {}
  }
  return (
    <main className="flex-grow w-full h-[264px] bg-blue-900 p-6">
      <Navbar options={OPTIONS} />
      <div className="bg-white p-4 shadow-md ">
        <div className="flex justify-between">
          {/* <p className="text-[16px] mb-3">Analytics</p> */}
          <div className="flex align-center items-center w-[373px] h-[42px] bg-neutral-800 border-solid border-2 border-inherit" style={{ marginLeft: 'auto' }}>
            <div className="justify-between flex" onClick={() => open()}>
              <div>
                <FilterIcon />
              </div>
              <div className="w-[325px] pl-2">Filter</div>
            </div>
            <div className="flex items-center h-full border-x w-full">
              <CSVIcon />
            </div>
            <FilterWordonModal />
          </div>
        </div>
        <div className="flex justify-between py-4">
          <div>
            <Typography size="caption" className="text-[16px] mb-3">
              Accessed Users
            </Typography>
          </div>
          <div>
            <Typography size="caption">
              Total No. of Users: {worldCount}
            </Typography>
          </div>
        </div>
        <TableHeader onChangeEntries={handleEntries} />
        <Table
          tableDate={usersData}
          //   searchItem={searchTerm}
          entries={entries}
          keysToFilter={check ? tableDataFilterUser : tableDataUser }
          tableHeaderRow={tableHeaderRowUser}
          totalDataCount={totalDataCount}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
        />
      </div>
    </main>
  );
};
