import CoreAPIService from "./CoreAPIService";
import { API_ENDPOINTS } from "../utils";
import { FieldValues } from "react-hook-form";

class FeaturesService {
  getFeatures = async (page: number, limit: number) =>
    CoreAPIService.get<FeaturesResponse>(
      `${API_ENDPOINTS.PRIVATE.FEATURES}/${page}/${limit}`
    );
  deleteFeatures = async (id: number) =>
    CoreAPIService.post<FeaturesResponse>(
      `${API_ENDPOINTS.PRIVATE.FEATURES_DELETE}/${id}`
    );
  addFeatures = async (payload: FieldValues) =>
    CoreAPIService.post<FeaturesResponse>(
      `${API_ENDPOINTS.PRIVATE.FEATURES_ADD}`,
      payload
    );
  addSet = async (payload: FieldValues) =>
    CoreAPIService.post<FeaturesResponse>(
      `${API_ENDPOINTS.PRIVATE.SET_ADD}`,
      payload
    );
  deleteSet = async (id: number) =>
    CoreAPIService.post<FeaturesResponse>(
      `${API_ENDPOINTS.PRIVATE.SET_DELETE}/${id}`
    );
    handlecheckUncheck = async (featureId: any,setId:any,isEnabled:any) =>
    CoreAPIService.post<FeaturesResponse>(
      `${API_ENDPOINTS.PRIVATE.FEATURES}/${setId}/${featureId}/${isEnabled}`
    );
    
    // handlecheckUncheck
    // handlecheckUncheck    = async (featureId: any,setId:any,isEnabled:any) => {
    //   const accessToken = checkAccessToken();
    //   let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.FEATURES}/${featureId}/${setId}/${isEnabled}`;
    //   try {
    //     return axios
    //       .post(url, { headers: { authorization: accessToken } })
    //       .then((response) => {
    //         return response.data;
    //       })
    //       .catch((error) => {
    //         return error.response.data;
    //       });
    //   } catch (error) {
    //     return error;
    //   }
    // };
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new FeaturesService();
