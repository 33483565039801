import { useMemo, useEffect, useState } from "react";

import { Button } from "../../../Atoms";
import { FormInput as Input } from "../../../Molecules";
import { FieldValues, useForm } from "react-hook-form";
import { AddFilterModalProps } from "./interface";
import moment from "moment";

type Option = {
  value: string;
  label: string;
};

const options: Option[] = [
  { value: "MUSEUM", label: "museum" },
  { value: "ENVIRONMENT", label: "environments" },
  { value: "USER", label: "xanaWorld" },
];

export const FilterWordModal = ({ save }: AddFilterModalProps) => {
  // const [minEndDate,setMinEndDate] = useState("")
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const option = options.find((opt) => opt.value === selectedValue) || null;
    setSelectedOption(option);
  };

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {

    const endTime = moment.utc(data.to).format("YYYY-MM-DDTHH:mm");

    const startTime = moment.utc(data.form).format("YYYY-MM-DDTHH:mm");
    const endDate = data.form;
    data = {
      ...data,
      start: data.to ? startTime : "",
      end: data.to ? endTime : "",
      world: data.world,
      type: selectedOption?.value,
    };
    const objData = { ...data };
    save?.(objData);
  };

  const formValues = watch()


  useEffect(() => {

    if(new Date(formValues.form).getTime() > new Date(formValues.to).getTime()){
      setValue('to', formValues.form)
    }

  }, [formValues.form])


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6 w-full px-4 ">
          <Input
            className="w-full dark:text-neutral-400"
            control={control}
            // defaultValue={startDateWatcher ?? minStartDate}
            error={errors?.startDate?.message?.toString()}
            label="World"
            placeholder="Enter world name"
            // min={moment(new Date()).format(DATE_TIME_FORMAT)}
            name="world"
            // required
            // rules={{
            //   required: { message: "world name can not be empty", value: true },
            // }}
            type="text"
          />
        </div>

        <div className="mb-6 w-full px-4 ">
          <label className="dark:text-neutral-400">Type:</label>
          <select
            value={selectedOption?.value || ""}
            onChange={handleOptionChange}
            className="block w-full px-4 py-1 mt-2  dark:text-neutral-400 bg-white border border-gray-300  focus:outline-none focus:border-blue-500"
          >
            <option value="">-- Select world--</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="-mx-3 mb-6 flex flex-wrap px-4">
          <div className="mb-6 w-full px-4 sm:mb-0 sm:w-1/2 md:mb-0 md:w-1/2">
            <Input
              className="w-full dark:text-neutral-400"
              control={control}
              // defaultValue={startDateWatcher ?? minStartDate}
              error={errors?.startDate?.message?.toString()}
              label="Form"
              // min={moment(new Date()).format(DATE_TIME_FORMAT)}
              name="form"
              value={formValues.form}
              // required
              // rules={{
              //   required: {
              //     message: "startDate can not be empty",
              //     value: true,
              //   },
              // }}
              type="datetime-local"
            />
          </div>
          <div className="w-full px-4 sm:w-1/2 md:w-1/2">
            <Input
              className="w-full dark:text-neutral-400"
              control={control}
              error={errors?.endDate?.message?.toString()}
              label="To"
              name="to"
              // required
              // rules={{
              //   required: { message: "endDate can not be empty", value: true },
              // }}
              type="datetime-local"
              value={formValues.to}
              min={formValues.form}
            />

            {/* <Input className="w-full dark:text-neutral-400" control={control} type="datetime-local" {...register("to")} /> */}
          </div>
        </div>
        <div className="mb-6 w-full px-4 py-4">
          <Button
            color="primary"
            size="small"
            variant="solid"
            type="submit"
            className="w-full !px-10"
          >
            Search
          </Button>
        </div>
      </form>
    </>
  );
};
