import CoreAPIService from './CoreAPIService'
import { API_ENDPOINTS, checkAccessToken } from '../utils'
import axios from "axios";

const BASE_API_ENDPOINT = process.env.REACT_APP_PUBLIC_NETWORK_TYPE === "testnet" ?  process.env.REACT_APP_PUBLIC_API_TESTNET_BACKEND_URL : process.env.REACT_APP_PUBLIC_API_MAINNET_BACKEND_URL;

class AllEventsService {
    getAllEvents = async (pageSize: number,pageNumber:number) => CoreAPIService.get<allEventsResponse>(`${API_ENDPOINTS.PRIVATE.USERCUSTOMEVENTS}/${pageSize}/${pageNumber}`)
    updateAllEvents = async (payload:  any) => {
       return CoreAPIService.post<allEventsResponse>(`${API_ENDPOINTS.PRIVATE.USERCUSTOMEVENTS_UPDATE_APPROVALSTATUS}`,payload)
    }
    reOrderEvents = async (payload:any) => {
        const accessToken = checkAccessToken();
          let url = `${BASE_API_ENDPOINT}${API_ENDPOINTS.PRIVATE.ORDEREVENT}`
        try {
          return axios
            .post(url, payload, { headers: { authorization: accessToken } })
            .then((response) => {
              return response.data;
            });
        } catch (error) {}
      };
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new AllEventsService()
