import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import type { RootState } from '../store'
import { checkAccessToken } from './storage'

export const ProtectedRoutes = ({ children }:any) => {
  const isAuth = useSelector((state: RootState) => state.auth.isLoggedIn)
  // const sidebar3 = useSelector((state: RootState) => state.auth.user)
  const token = checkAccessToken()
  
  if ( !token) {
    return <Navigate to="/" replace={true} />
  }

  return children
}

