import React, { useMemo, useEffect } from "react";
import { Button, Select, Typography } from "../../../Atoms";
import { FormInput as Input } from "../..";
import { useForm } from "react-hook-form";
import { Checkbox } from "../../../Atoms/Checkbox";
import { useState } from "react";
import { category, dataForm, gender, subcategory, version } from "./utils";
import { AddItemModalProps } from "./interface";
export const EditItemModal = ({ save,rowData }: AddItemModalProps) => {
 
  const [checkBoxData, setCheckBoxData] = useState({
    paid: false,
    purchased: rowData?.item?.isPurchased,
    occupied: false,
    favorite: false,
  });
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
     
    const version=rowData?.item?.version
    const itemId=rowData?.item?.id
    const name=rowData?.item?.name
    const isPurchased = checkBoxData?.purchased;
    const obj = {
      version,
      itemId,
      name,
      isPurchased,
    };
    const objData = {...data,...obj };
    save?.(objData);
  };
  const labelTextClassName = [
    "mb-1 font-semibold text-neutral-100 dark:text-neutral-600",
  ].join(" ");

  useEffect(() => {
    if (rowData?.item?.price !== undefined) {
      setValue("price", rowData?.item?.price);
    }
  }, [rowData])

  const { price } = watch();

  useMemo(() => {
    const numericValue = parseFloat(price);

    if (!isNaN(numericValue) && numericValue >= 0) {
      setValue("price", numericValue);
    } else {
      setValue("price", "");
    }
  }, [price]);
  return (
    <form
      className="md:!w-[650px] max-h-[500px] overflow-auto overflow-y-auto overflow-x-hidden"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-full px-4 sm:w-1/2 md:w-full">
        <Input
          required
          className="w-full dark:text-neutral-400"
          control={control}
          error={errors?.Price?.message?.toString()}
          label="Price"
          name="price"
          placeholder="Price"
          type="number"
          min={0}
          defaultValue={rowData?.item?.price} 
        />
      </div>
      <div className="mb-6 flex gap-[100px] w-full px-4 py-4">
        <div>
          <Checkbox
            value={checkBoxData.purchased}
            className="mb-4"
            checked={checkBoxData?.purchased}
            label="Purchased"
            onChange={(value) =>
              setCheckBoxData((prev) => ({ ...prev, purchased: value }))
            }
          />
        </div>
      </div>
      <div className="mb-6 w-full flex justify-end px-4 py-4">
        <Button
          color="primary"
          size="small"
          variant="solid"
          type="submit"
          className="w-[200px] !px-10"
        >
          Save change
        </Button>
      </div>
    </form>
  );
};
