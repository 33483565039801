export type Item = {
  value: string;
  label: string;
};

const emptyArrays: Item[] = new Array(11).fill([]);

export const version: any[] = emptyArrays.map((_, index) => {
  return {
    value: index + 1,
    label: index + 1,
  };
});
export const selectType = [
  {
    value: "GLOBAL",
    label: "GLOBAL",
  },
  {
    value: "CHINA",
    label: "CHINA",
  },
  {
    value: "ALL",
    label: "ALL",
  },
];

export const dataForm = (data: any) => {
  const formData = new FormData();
   Object.entries(data).forEach(([key, value]) => {
     formData.append(key, data[key]);
  });
  return formData
};